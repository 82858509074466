
<div fxLayout="column" class="h-100">
    <div fxFlex="none" class="p-6 border-bottom">
        <h1>{{data.title}}</h1>
    </div>
    <ng-container>
      <div fxFlex="none" class="p-6 y-auto">
          <p>{{data.message}}</p>
      </div>
      <div fxFlex="none" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px" class="px-6 pb-6">
          <button mat-stroked-button color="primary" (click)="onClose()">Cancel</button>
          <button mat-flat-button color="primary" (click)="onConfirm()">Confirm</button>
      </div>
    </ng-container>
</div>
