<mat-dialog-content class="mat-typography" style="height: calc(91vh - 98px);">
  <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="space-between center" class="mb-2 border-bt">
    <div class="steps">{{data.type === 'csv' ? 'Import data from mounted drive' : 'Select the
      folder or file'}}</div>
    <button mat-icon-button class="close-button" [mat-dialog-close]="true">
      <mat-icon class="close-icon">close</mat-icon>
    </button>
  </div>
  <div fxLayout="column" fxLayoutGap="16px">
    <div fxFlex>
      <mat-form-field appearance="outline">
        <mat-label>Search</mat-label>
        <mat-icon matPrefix>search</mat-icon>
        <input matInput placeholder="Search" (input)="filterNodes($event)" />
      </mat-form-field>
    </div>
    <div fxFlex>
      <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
        <!-- Leaf node template -->
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding [class.selected]="isFileSelected(node)"
          (click)="toggleFileSelection(node);$event.stopPropagation()">
          <button mat-icon-button disabled></button>
          <mat-icon class="material-symbols-outlined">{{node.type === 'file' ? 'insert_drive_file' :
            'folder'}}</mat-icon>
          {{node.name}}
          <div class="check" *ngIf="isFileSelected(node)"><mat-icon>check</mat-icon></div>
        </mat-tree-node>
        <!-- Expandable node template -->
        <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding
          [class.selected]="isFileSelected(node) && data.type==='save'"
          (click)="toggleFileSelection(node);$event.stopPropagation()">
          <div fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="4px">
            <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'toggle ' + node.name">
              <mat-icon class="mat-icon-rtl-mirror">
                {{treeControl.isExpanded(node) ? 'arrow_drop_down' : 'arrow_right'}}
              </mat-icon>
            </button>
            <mat-icon class="material-symbols-outlined">{{node.type === 'file' ? 'insert_drive_file' :
              'folder'}}</mat-icon>
            <div>{{node.name}}</div>
          </div>
          <ul [class.example-tree-invisible]="!treeControl.isExpanded(node)" class="my-0">
            <ng-container matTreeNodeOutlet></ng-container>
          </ul>
        </mat-nested-tree-node>
      </mat-tree>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-stroked-button color="primary" mat-dialog-close>Cancel</button>
  <button mat-flat-button color="primary" (click)="onImport()">{{data.type === 'csv' ? 'Import' : 'Select'}}</button>
</mat-dialog-actions>