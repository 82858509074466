import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfigService, WorkflowCanvasService } from 'src/app/pages/workflow-designer/workflow-canvas.service';
import { WidgetControl } from 'src/app/controls/widget-control/widget-control';

@Component({
  selector: 'app-data-visualization',
  templateUrl: './data-visualization.component.html',
  styleUrls: ['./data-visualization.component.less'],
})
export class DataVisualizationComponent {
  plots:any[] = [];
  isLoading: boolean[] = [];

  public widgetControl: WidgetControl | undefined = undefined;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DataVisualizationComponent>,
    private configService: ConfigService
  ) {
  }

  ngOnInit() {
  }


  refreshData() {
  }

}
