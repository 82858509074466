import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as Plotly from 'plotly.js-dist-min';
import { DataSheetGenerateService } from './data-sheet-generate.service';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { SnackBarNotificationService } from 'src/app/services/snack-bar-notification.service';
import { Project } from 'src/app/models/project-models';
import { ConfigService } from 'src/app/services/config.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
interface CSVResponse {
  csv_data: DataItem[];
}
interface DataItem {
  file_name: string;
  xdata: string;
  ydata: string;
}

interface DataPoint {
  Rm: number;
  Ag: number;
  E: number;
}
@Component({
  selector: 'app-data-sheet-generator',
  templateUrl: './data-sheet-generator.component.html',
  styleUrls: ['./data-sheet-generator.component.less'],
})
export class DataSheetGeneratorComponent implements OnInit {
  loader: boolean = false;
  editState: { [key: string]: boolean } = {};
  isEditingCR: { [key: string]: boolean } = {};
  productName: string = 'Default commercial name';
  gauge: number = 1;
  m_source: string = 'Europe';
  branchName: string = 'production';
  specName: string = 'specA';
  latestRevalidationDate: Date = new Date('2024-01-01');
  validUntilDate: Date = new Date('2024-01-01');
  selectedTensileSample: string = '';
  editMode: { [key: string]: boolean } = {};
  datasheetId!: number;
  nominalAge!: number;
  dataSheetResults: any;
  bulgeFiles: string[] = [];
  bulgePlotDataPath: string = '';
  tensileSelectedOptions: string[] = [];
  tensileFilteredOptions: string[] = [];
  tensileReasons: string[] = new Array(this.tensileFilteredOptions.length);
  bulgeSelectedOptions: string[] = [];
  bulgeFilteredOptions: string[] = [];
  bulgeReasons: string[] = new Array(this.bulgeFilteredOptions.length);
  showMainHeader: boolean = false;
  toggleHeaderButton: string = 'expand_more';
  showDropdown: boolean = true;
  uniqueParameters: string[] = [];
  tensilePanel: boolean = false;
  bulgePanel: boolean = false;
  correctedTensileGraph: boolean = false;
  dataSheetGenerated: boolean = false;
  dataSheetFilePath: string = '';
  recomputeRp02: boolean = true;
  streamLiteLoadData: boolean = false;
  modelAdjust: string = 'Hocket Sherby Swift';
  scale_TensileBulge: boolean = false;
  modelComparisonData: any = '';
  traces: any = [];
  tabIndex: number = 0;
  modelResults: any = '';
  showTabs: boolean = false;
  activeTabIndexStressStrain: number = 0;
  activeTabIndexNValues: number = 0;
  modelGraphLoader: boolean = false;
  tensile_grad: number = 15;
  bulge_grad: number = 10;
  tensile_n_grad: number = 15;
  bulge_n_grad: number = 10;
  tensile_plot_data: DataItem[] = [];
  bulge_plot_data: DataItem[] = [];
  tensile_0_data: DataItem[] = [];
  tensile_x_data: number[] = [];
  tensile_y_data: number[] = [];
  correctedTensileParams: any;
  selectedDirectory: any;
  formData: FormData = new FormData();
  selectedFiles: any;
  filesFromFolder: any;
  foldersDetailList: any;
  parentFolderName: any;
  filesPaths: any;
  selectedFolder: String = '';
  dataSheetsArray: any = [];
  nominalAgeArray: any = [];
  uploadLoader: boolean = false;
  tensileExcludedData: { file_name: string; reason: string }[] = [];
  bulgeExcludedData: { file_name: string; reason: string }[] = [];
  tensileInputChanged: any[];
  bulgeInputChanged: any[];
  editingCell: { rowKey: string; colKey: string } | null = null;
  roundingRules: any = {
    Rp02: 2,
    Rm: 2,
    Ag: 4,
    Agt: 4,
    A80: 4,
    E: 1,
    E_c: 2,
    nu: 3,
    nu_c: 5,
    r4_6: 4,
    r8_12: 4,
    r2_20: 4,
    r10_15: 4,
    n4_6: 4,
    n10_15: 4,
    n10_20: 4,
    n2_20: 4,
  };
  dataSheetSpinner: boolean = false;
  tensileRecentlyChangedFiles = new Set<string>();
  bulgeRecentlyChangedFiles = new Set<string>();
  showButtons: boolean = false;
  projectId: string = '';
  private hasChanged: boolean = false;
  basePath: string = 'base';

  constructor(
    private cd: ChangeDetectorRef,
    private datasheetService: DataSheetGenerateService,
    private http: HttpClient,
    private snackBarNotificationService: SnackBarNotificationService,
    private configService: ConfigService,
    private router: Router,
    public toaster: ToastrService,
    private errorHandlerService: ErrorHandlerService,
  ) {
    this.tensileInputChanged = new Array(
      this.tensileFilteredOptions.length,
    ).fill(false);
    this.bulgeInputChanged = new Array(this.bulgeFilteredOptions.length).fill(
      false,
    );

    const selectedProjectId: string | undefined =
      this.configService.SelectedProjectId;
    selectedProjectId ? (this.projectId = selectedProjectId) : null;
  }

  ngOnInit() {
    this.getDataSheetSource();
  }

  getDataSheetSource() {
    let obj = {
      base_path: 'base',
    };
    this.datasheetService.getDataSheetNumber(obj, this.projectId).subscribe({
      next: (data: any) => {
        this.dataSheetsArray = data.datasheets.sort((a: any, b: any) => {
          return parseInt(a) - parseInt(b);
        });
      },
      error: (error) => {
        console.log(error);
        this.errorHandlerService.handleError(error);
      },
    });
  }

  get tensileWeight(): number {
    return this.dataSheetResults.hss_weights_object.tensile_weight;
  }

  set tensileWeight(value: number) {
    this.dataSheetResults.hss_weights_object.tensile_weight = value;
  }

  get bulgeWeight(): number {
    return this.dataSheetResults.hss_weights_object.bulge_weight;
  }

  set bulgeWeight(value: number) {
    this.dataSheetResults.hss_weights_object.bulge_weight = value;
  }

  get considereWeight(): number {
    return this.dataSheetResults.hss_weights_object.considere_weight;
  }

  set considereWeight(value: number) {
    this.dataSheetResults.hss_weights_object.considere_weight = value;
  }

  get scaleTensileBulge(): boolean {
    return this.scale_TensileBulge;
  }

  set scaleTensileBulge(value: boolean) {
    this.scale_TensileBulge = value;
  }

  getStressFitLowerBound(): number {
    return this.dataSheetResults.corrected_tensile_results.s_min;
  }

  getStressFitUpperBound(): number {
    return this.dataSheetResults.corrected_tensile_results.s_max;
  }

  setStressFitLowerBound(value: number): void {
    this.dataSheetResults.corrected_tensile_results.s_min = value;
  }

  setStressFitUpperBound(value: number): void {
    this.dataSheetResults.corrected_tensile_results.s_max = value;
  }

  resetConfig() {
    this.onDataChange(true);
  }

  onDataChange(reset: boolean, option?: string) {
    this.showButtons = false;
    if (option == 'sheetNumber') {
      this.nominalAge = -1;
      let obj = {
        base_path: this.datasheetId,
      };
      this.datasheetService.getNominalAge(obj, this.projectId).subscribe({
        next: (data: any) => {
          this.nominalAgeArray = data.datasheets;
          this.nominalAgeArray.sort((a: string, b: string) => {
            const numericA = parseInt(a, 10);
            const numericB = parseInt(b, 10);
            return numericA - numericB;
          });
        },
        error: (error) => {
          console.log(error);
          this.errorHandlerService.handleError(error);
        },
      });
    }

    if (this.datasheetId && this.nominalAge !== -1) {
      this.loader = true;
      const siteId = 1;
      this.dataSheetResults = null;
      this.tensileExcludedData = [];
      this.bulgeExcludedData = [];
      const datasheetId = Number(this.datasheetId);
      const nominalAge = Number(this.nominalAge);
      this.datasheetService
        .getDatasheet(siteId, this.projectId, datasheetId, nominalAge, reset)
        .subscribe({
          next: (response) => {
            try {
              this.dataSheetResults = response.datasheet[0];
              this.showButtons = true;
              this.dataSheetResults.corrected_tensile_results = JSON.parse(
                this.dataSheetResults.corrected_tensile_results,
              );

              if (this.dataSheetResults) {
                this.process_TensileData(
                  this.dataSheetResults.tensile_files,
                  this.dataSheetResults.tensile_plot_data_path,
                );
                this.process_BulgeData(
                  this.dataSheetResults.bulge_files,
                  this.dataSheetResults.bulge_plot_data_path,
                );
                this.process_correctedTensileResults(
                  this.dataSheetResults.tensile_files,
                  this.dataSheetResults.corrected_tensile_results,
                );
                setTimeout(() => {
                  this.computeLocalFit(false);
                }, 2000);

                this.updateMetadata();
                this.updateFitParameters();
              }

              if (this.dataSheetResults.preview) {
                this.dataSheetGenerated = true;
              } else {
                this.dataSheetGenerated = false;
              }
              this.streamLiteLoadData = true;
            } catch (error) {
              console.error('Error processing the datasheet:', error);
              this.showButtons = false;
              this.errorHandlerService.handleError(error);
              this.dataSheetResults = null;
              this.streamLiteLoadData = false;
            } finally {
              this.loader = false;
            }
          },
          error: (error) => {
            console.log(error);
            this.errorHandlerService.handleError(error);
            this.dataSheetResults = null;
            this.loader = false;
            this.streamLiteLoadData = false;
            this.showButtons = false;
          },
        });
    }
  }

  updateMetadata() {
    if (
      this.dataSheetResults.metadata &&
      Object.keys(this.dataSheetResults.metadata).length > 0
    ) {
      let metadata = this.dataSheetResults.metadata;
      this.productName = metadata.commercial_name;
      this.gauge = metadata.gauge;
      this.m_source = metadata.m_source;
      this.branchName = metadata.branch_name;
      this.specName = metadata.spec_name;

      this.latestRevalidationDate = new Date(metadata.latest_revalidation);
      this.validUntilDate = new Date(metadata.valid_until);
    } else {
      this.resetMetaDataChanges();
    }
  }

  resetMetaDataChanges() {
    this.productName = 'Default commercial name';
    this.gauge = 1;
    this.m_source = 'Europe';
    this.branchName = 'production';
    this.specName = 'specA';
    this.latestRevalidationDate = new Date('2024-01-01');
    this.validUntilDate = new Date('2024-01-01');
  }

  updateFitParameters() {
    if (
      this.dataSheetResults.new_fit_params &&
      Object.keys(this.dataSheetResults.new_fit_params).length > 0
    ) {
      let gradients = this.dataSheetResults.new_fit_params.gradients;
      this.tensile_grad = gradients.tensile_grad;
      this.bulge_grad = gradients.bulge_grad;
      this.tensile_n_grad = gradients.tensile_n_grad;
      this.bulge_n_grad = gradients.bulge_n_grad;
      this.recomputeRp02 = this.dataSheetResults.new_fit_params.recompute;
      this.getTensileExcludedData(
        this.dataSheetResults.tensile_files,
        this.dataSheetResults.new_fit_params.tensileExcludedData,
      );
      this.getBulgeExcludedData(
        this.dataSheetResults.bulge_files,
        this.dataSheetResults.new_fit_params.bulgeExcludedData,
      );
    }
  }

  getTensileExcludedData(allFiles: string[], excludedData: any[]) {
    this.tensileSelectedOptions = [];
    this.tensileExcludedData = excludedData;
    const excludedFileNames = new Set(
      excludedData.map((item) => item.file_name),
    );
    allFiles.forEach((file) => {
      if (!excludedFileNames.has(file)) {
        this.tensileSelectedOptions.push(file);
      }
    });
    this.tensilePanel = true;
    this.tensile_GraphPlot(
      this.tensileSelectedOptions,
      this.dataSheetResults.tensile_plot_data_path,
    );
  }

  getBulgeExcludedData(allFiles: string[], excludedData: any[]) {
    this.bulgeSelectedOptions = [];
    this.bulgeExcludedData = excludedData;
    const excludedFileNames = new Set(
      excludedData.map((item) => item.file_name),
    );
    allFiles.forEach((file) => {
      if (!excludedFileNames.has(file)) {
        this.bulgeSelectedOptions.push(file);
      }
    });
    this.bulgePanel = true;
    this.bulge_GraphPlot(
      this.bulgeSelectedOptions,
      this.dataSheetResults.bulge_plot_data_path,
    );
  }

  process_TensileData(
    tensileFiles: string[],
    tensilePlotDataPath: string,
  ): void {
    this.tensileSelectedOptions = [...tensileFiles];
    this.tensileFilteredOptions = tensileFiles.filter(
      (option) => !this.tensileSelectedOptions.includes(option),
    );
    this.tensile_GraphPlot(this.tensileSelectedOptions, tensilePlotDataPath);
  }

  tensile_GraphPlot(
    tensileSelectedOptions: string[],
    tensilePlotDataPath: string,
  ): void {
    let obj = {
      project_id: this.projectId,
      datasheet: this.datasheetId,
      nominal_age: this.nominalAge,
      file_path: tensilePlotDataPath,
    };
    this.datasheetService.readCSV(obj).subscribe({
      next: (response: CSVResponse) => {
        const data = response.csv_data;
        this.tensile_plot_data = data;
        const included_samples = tensileSelectedOptions;
        const figData: {
          x: number[];
          y: number[];
          mode: string;
          name: string;
        }[] = [];
        for (const includedSample of included_samples) {
          const fileLines = data.filter(
            (item) => item.file_name === includedSample,
          );
          if (fileLines.length > 0) {
            const xdata = fileLines.map((item) => parseFloat(item.xdata));
            this.tensile_x_data = xdata;
            const ydata = fileLines.map((item) => parseFloat(item.ydata));
            this.tensile_y_data = ydata;
            figData.push({
              x: xdata,
              y: ydata,
              mode: 'lines',
              name: includedSample,
            });
          }
        }
        const layout = {
          height: 400,
          title: 'Stress vs strain plot of included files',
          legend: { x: 1.1, y: 1.0 },
        };
        Plotly.newPlot('plotly-div-1', figData, layout);
      },
      error: (error) => {
        console.error('Error loading CSV data:', error);
        this.errorHandlerService.handleError(error);
      },
    });
  }

  process_BulgeData(bulgeFiles: string[], bulgePlotDataPath: string): void {
    this.bulgeSelectedOptions = [...bulgeFiles];
    this.bulgeFilteredOptions = bulgeFiles.filter(
      (option) => !this.bulgeSelectedOptions.includes(option),
    );
    this.bulge_GraphPlot(this.bulgeSelectedOptions, bulgePlotDataPath);
  }

  bulge_GraphPlot(
    bulgeSelectedOptions: string[],
    bulgePlotDataPath: string,
  ): void {
    let obj = {
      project_id: this.projectId,
      datasheet: this.datasheetId,
      nominal_age: this.nominalAge,
      file_path: bulgePlotDataPath,
    };
    this.datasheetService.readCSV(obj).subscribe({
      next: (response: CSVResponse) => {
        const data = response.csv_data;
        this.bulge_plot_data = data;
        const included_samples = bulgeSelectedOptions;
        const figData: {
          x: number[];
          y: number[];
          mode: string;
          name: string;
        }[] = [];
        for (const includedSample of included_samples) {
          const fileLines = data.filter(
            (item) => item.file_name === includedSample,
          );
          if (fileLines.length > 0) {
            const xdata = fileLines.map((item) => parseFloat(item.xdata));
            const ydata = fileLines.map((item) => parseFloat(item.ydata));
            figData.push({
              x: xdata,
              y: ydata,
              mode: 'lines',
              name: includedSample,
            });
          }
        }
        const layout = {
          height: 400,
          title: 'Stress/strain plot of included files',
          legend: { x: 1.1, y: 1.0 },
        };
        Plotly.newPlot('plotly-div-2', figData, layout);
      },
      error: (error) => {
        console.error('Error loading CSV data:', error);
        this.errorHandlerService.handleError(error);
      },
    });
  }

  add(event: any, type: string): void {
    if (type == 'tensile') {
      const value = event.value.trim();
      if (value && !this.tensileSelectedOptions.includes(value)) {
        this.tensileSelectedOptions.push(value);
        this.tensileFilteredOptions = this.tensileFilteredOptions.filter(
          (option) => option !== value,
        );
        this.tensile_GraphPlot(
          this.tensileSelectedOptions,
          this.dataSheetResults.tensile_plot_data_path,
        );
      }
      event.input.value = '';
    }
    if (type == 'bulge') {
      const value = event.value.trim();
      if (value && !this.bulgeSelectedOptions.includes(value)) {
        this.bulgeSelectedOptions.push(value);
        this.bulgeFilteredOptions = this.bulgeFilteredOptions.filter(
          (option) => option !== value,
        );
        this.bulge_GraphPlot(
          this.bulgeSelectedOptions,
          this.dataSheetResults.bulge_plot_data_path,
        );
      }
      event.input.value = '';
    }
  }

  removeAll(type: string): void {
    if (type === 'tensile') {
      if (this.tensileSelectedOptions.length > 1) {
        this.tensileSelectedOptions.slice(0, -1).forEach((option) => {
          const existingIndex = this.tensileExcludedData.findIndex(
            (item) => item.file_name === option,
          );
          if (existingIndex === -1) {
            this.tensileExcludedData.push({ file_name: option, reason: '' });
          }
        });
        this.tensileSelectedOptions = this.tensileSelectedOptions.slice(-1);
      }

      this.tensilePanel = true;
      this.tensile_GraphPlot(
        this.tensileSelectedOptions,
        this.dataSheetResults.tensile_plot_data_path,
      );
    } else if (type === 'bulge') {
      if (this.bulgeSelectedOptions.length > 1) {
        this.bulgeSelectedOptions.slice(0, -1).forEach((option) => {
          const existingIndex = this.bulgeExcludedData.findIndex(
            (item) => item.file_name === option,
          );
          if (existingIndex === -1) {
            this.bulgeExcludedData.push({ file_name: option, reason: '' });
          }
        });
        this.bulgeSelectedOptions = this.bulgeSelectedOptions.slice(-1);
      }

      this.bulgePanel = true;
      this.bulge_GraphPlot(
        this.bulgeSelectedOptions,
        this.dataSheetResults.bulge_plot_data_path,
      );
    }
  }

  remove(option: string, type: string): void {
    if (type === 'tensile') {
      if (this.tensileSelectedOptions.length > 1) {
        const index = this.tensileSelectedOptions.indexOf(option);
        if (index >= 0) {
          this.tensileSelectedOptions.splice(index, 1);
          this.tensileFilteredOptions.push(option);
          this.tensileExcludedData.push({ file_name: option, reason: '' });
          this.tensilePanel = true;
          this.tensile_GraphPlot(
            this.tensileSelectedOptions,
            this.dataSheetResults.tensile_plot_data_path,
          );
        }
      } else {
        this.toaster.error(
          'At least one file should remain in the tensile list',
          '',
          {
            positionClass: 'custom-toast-position',
          },
        );
      }
    }

    if (type === 'bulge') {
      if (this.bulgeSelectedOptions.length > 1) {
        const index = this.bulgeSelectedOptions.indexOf(option);
        if (index >= 0) {
          this.bulgeSelectedOptions.splice(index, 1);
          this.bulgeFilteredOptions.push(option);
          this.bulgeExcludedData.push({ file_name: option, reason: '' });
          this.bulgePanel = true;
          this.bulge_GraphPlot(
            this.bulgeSelectedOptions,
            this.dataSheetResults.bulge_plot_data_path,
          );
        }
      } else {
        this.toaster.error(
          'At least one file should remain in the bulge list.',
          '',
          {
            positionClass: 'custom-toast-position',
          },
        );
      }
    }
  }

  selected(key: any, type: string): void {
    if (type == 'tensile') {
      const value = key;
      if (!this.tensileSelectedOptions.includes(value)) {
        this.tensileSelectedOptions.push(value);
        this.tensileFilteredOptions = this.tensileFilteredOptions.filter(
          (option) => option !== value,
        );
        this.tensile_GraphPlot(
          this.tensileSelectedOptions,
          this.dataSheetResults.tensile_plot_data_path,
        );
      }
    }
    if (type == 'bulge') {
      const value = key;
      if (!this.bulgeSelectedOptions.includes(value)) {
        this.bulgeSelectedOptions.push(value);
        this.bulgeFilteredOptions = this.bulgeFilteredOptions.filter(
          (option) => option !== value,
        );
        this.bulge_GraphPlot(
          this.bulgeSelectedOptions,
          this.dataSheetResults.bulge_plot_data_path,
        );
      }
    }
  }

  focusOptions(key: string) {
    if (key === 'tensile') {
      this.tensilePanel = !this.tensilePanel;
    } else {
      this.bulgePanel = !this.bulgePanel;
    }
  }

  toggleMainHeader() {
    this.showMainHeader = !this.showMainHeader;
    this.toggleHeaderButton = this.showMainHeader
      ? 'expand_less'
      : 'expand_more';
  }

  getSignatures(): string[] {
    return this.dataSheetResults
      ? Object.keys(this.dataSheetResults.hss_complete_params)
      : [];
  }

  parametersWithOutParam_value(): string[] {
    const allParams = new Set<string>();
    const signatures = this.getSignatures();
    for (const signature of signatures) {
      const params = Object.keys(
        this.dataSheetResults.hss_complete_params[signature],
      );
      params.forEach((param) => {
        if (param !== 'param_value') {
          allParams.add(param);
        }
      });
    }
    return Array.from(allParams);
  }

  parametersOnlyParam_value(): string[] {
    const allParams = new Set<string>();
    const signatures = this.getSignatures();
    for (const signature of signatures) {
      const params = Object.keys(
        this.dataSheetResults.hss_complete_params[signature],
      );
      params.forEach((param) => {
        if (param == 'param_value') {
          allParams.add(param);
        }
      });
    }
    return Array.from(allParams);
  }

  enableEdit(param: string, signature: string): void {
    this.editState[`${param}-${signature}`] = true;
    setTimeout(() => {
      const inputElement = document.querySelector(
        `input[data-param="${param}"][data-signature="${signature}"]`,
      );
      if (inputElement instanceof HTMLElement) {
        inputElement.focus();
      }
    }, 0);
  }

  disableEdit(param: string, signature: string): void {
    this.editState[`${param}-${signature}`] = false;
  }

  isEditing(param: string, signature: string): boolean {
    return !!this.editState[`${param}-${signature}`];
  }

  formatDate(date: Date): string {
    const year = date.getFullYear();
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const day = ('0' + date.getDate()).slice(-2);
    return `${year}-${month}-${day}`;
  }

  generateDataSheet(fit_type: string) {
    this.dataSheetSpinner = true;
    if (!this.isMetadataComplete()) {
      this.dataSheetSpinner = false;
      this.toaster.error(
        'Metadata is incomplete. Please complete all required fields.',
        '',
        {
          positionClass: 'custom-toast-position',
        },
      );
      return;
    }

    this.dataSheetGenerated = false;
    let obj = {
      datasheet: Number(this.datasheetId),
      commercial_name: this.productName,
      gauge: this.gauge,
      m_source: this.m_source,
      latest_revalidation: this.formatDate(this.latestRevalidationDate),
      valid_until: this.formatDate(this.validUntilDate),
      spec_name: this.specName,
      branch_name: this.branchName,
    };
    let siteId = 1;

    // Proceed with API call
    this.datasheetService
      .generateDataSheet(
        siteId,
        this.projectId,
        this.datasheetId,
        this.nominalAge,
        obj,
        fit_type,
      )
      .subscribe({
        next: (results) => {
          this.dataSheetGenerated = true;
          this.dataSheetSpinner = false;
          this.dataSheetResults.pdf_path = results.datasheet_path.datasheet;
        },
        error: (error) => {
          console.log(error);
          this.dataSheetSpinner = false;
          this.dataSheetGenerated = false;
          this.toaster.error(
            'Unable to generate datasheet, please try again',
            '',
            {
              positionClass: 'custom-toast-position',
            },
          );
        },
      });
  }

  private isMetadataComplete(): boolean {
    if (
      !this.datasheetId ||
      !this.productName ||
      !this.gauge ||
      !this.m_source ||
      !this.latestRevalidationDate ||
      !this.validUntilDate ||
      !this.specName ||
      !this.branchName
    ) {
      return false;
    }
    return true;
  }

  onFocus() {
    this.hasChanged = false;
  }
  onBlur() {
    if (this.hasChanged) {
      this.saveMetadata();
    }
  }
  onChange() {
    this.hasChanged = true;
  }

  saveMetadata() {
    if (!this.isMetadataChanged()) {
      return;
    }
    const siteId = '1';
    let obj = {
      commercial_name: this.productName,
      gauge: this.gauge,
      m_source: this.m_source,
      latest_revalidation: this.formatDate(this.latestRevalidationDate),
      valid_until: this.formatDate(this.validUntilDate),
      spec_name: this.specName,
      branch_name: this.branchName,
    };

    this.datasheetService
      .saveMetadata(siteId, this.projectId, this.datasheetId.toString(), obj)
      .subscribe({
        next: (response) => {
          this.toaster.success('Metadata saved successfully', '', {
            positionClass: 'custom-toast-position',
          });
        },
        error: (error) => {
          this.toaster.error('Unable to save metadata, please try again', '', {
            positionClass: 'custom-toast-position',
          });
        },
      });
  }

  private isMetadataChanged(): boolean {
    const newMetadata: any = {
      commercial_name: this.productName,
      gauge: this.gauge,
      m_source: this.m_source,
      latest_revalidation: this.formatDate(this.latestRevalidationDate),
      valid_until: this.formatDate(this.validUntilDate),
      spec_name: this.specName,
      branch_name: this.branchName,
    };

    for (let key in newMetadata) {
      if (
        newMetadata[key] === '' ||
        newMetadata[key] === null ||
        newMetadata[key] === undefined
      ) {
        this.toaster.error(`${key.replace('_', ' ')} should not be empty`, '', {
          positionClass: 'custom-toast-position',
        });
        return false;
      }
    }

    for (let key in newMetadata) {
      if (newMetadata[key] !== this.dataSheetResults.metadata[key]) {
        return true;
      }
    }
    return false;
  }

  previewDataSheet() {
    const object = {
      project_id: this.projectId,
      datasheet: this.datasheetId,
      nominal_age: this.nominalAge,
      file_path: this.dataSheetResults.pdf_path,
    };

    this.datasheetService.previewDatasheet(object).subscribe({
      next: (response: any) => {
        if (response.file_name && response.file_url) {
          const pdfUrl = `${environment.apiUrl}/${response.file_url}`;
          window.open(pdfUrl, '_blank');
        } else {
          console.error(
            'Invalid response format. Missing file_name or file_url.',
          );
        }
      },
      error: (error) => {
        console.error('Error previewing datasheet:', error);
        this.errorHandlerService.handleError(error);
      },
    });
  }

  downloadDataSheetResults() {
    let datasheet = this.datasheetId.toString();

    this.datasheetService
      .downloadDatasheet(this.projectId, datasheet)
      .subscribe({
        next: (response: Blob) => {
          const blobUrl = window.URL.createObjectURL(response);
          const link = document.createElement('a');
          link.href = blobUrl;
          link.setAttribute('download', 'results.zip');
          document.body.appendChild(link);
          link.click();
          window.URL.revokeObjectURL(blobUrl);
          link.remove();
        },
        error: (error) => {
          console.error('Error downloading datasheet results:', error);
          this.errorHandlerService.handleError(error);
        },
      });
  }

  process_correctedTensileResults(tensile_files: string[], results: any) {
    if (tensile_files && tensile_files.length > 0) {
      const defaultFile = tensile_files.find(
        (file) => file === results.sample_name,
      );
      this.selectedTensileSample = defaultFile ? defaultFile : tensile_files[0];
      setTimeout(() => {
        this.generateCorrectedTensileGraph(results);
      }, 2000);
    }
  }

  generateCorrectedTensileGraph(result: any) {
    const data = {
      xdata: result.xdata,
      ydata: result.ydata,
      x_E: result.x_E,
      y_E: result.y_E,
      x_E_Rp02: result.x_E_Rp02.map((x: any) => x + 0.002),
      sig_Rp02: result.sig_Rp02,
      Agt: result.Agt,
      Rm: result.Rm,
      sample_name: result.sample_name,
      s_min: result.s_min,
      s_max: result.s_max,
      E: result.df_results[0].E,
      eps_Rp02: 0,
    };
    data['eps_Rp02'] = 0.2 / 100 + data.sig_Rp02 / data.E;

    const trace1 = {
      x: data.xdata,
      y: data.ydata,
      mode: 'lines',
      name: data.sample_name,
      line: { color: 'blue' },
    };

    const trace2 = {
      x: data.x_E,
      y: data.y_E,
      mode: 'lines',
      name: 'Elastic Modulus',
      line: { color: 'red' },
    };

    const traceElasticModulusFitBoundary = {
      x: [data.s_min / data.E, data.s_max / data.E],
      y: [data.s_min, data.s_max],
      mode: 'markers',
      name: 'Elastic Modulus fit boundary',
      marker: { color: 'red' },
    };

    const slope = (data.y_E[1] - data.y_E[0]) / (data.x_E[1] - data.x_E[0]);
    const x_Rp02_start = data.eps_Rp02 - data.sig_Rp02 / data.E;
    const x_Rp02_end = data.eps_Rp02 + data.sig_Rp02 / data.E;

    const traceRp02TangentModulus = {
      x: [x_Rp02_start, x_Rp02_end],
      y: [
        data.sig_Rp02 - slope * (data.eps_Rp02 - x_Rp02_start),
        data.sig_Rp02 + slope * (x_Rp02_end - data.eps_Rp02),
      ],
      mode: 'lines',
      name: 'Rp02 tangent modulus',
      line: { color: 'green' },
    };

    const traceRp02Point = {
      x: [data.x_E_Rp02[0]],
      y: [data.sig_Rp02[0]],
      mode: 'markers',
      name: 'Rp02',
      marker: { color: 'purple', size: 8 },
    };

    const traceAgtRm = {
      x: [data.Agt],
      y: [data.Rm],
      mode: 'markers',
      name: 'Agt/Rm',
      marker: { color: 'orange', size: 8 },
    };

    const figData = [
      trace1,
      trace2,
      traceElasticModulusFitBoundary,
      traceRp02TangentModulus,
      traceRp02Point,
      traceAgtRm,
    ];
    const layout = {
      height: 400,
      title: 'Stress/strain plot of ' + data.sample_name,
      xaxis: {
        title: 'Strain',
      },
      yaxis: {
        title: 'Stress',
      },
      legend: {
        x: 1.1,
        y: 1.0,
      },
    };

    Plotly.newPlot('plotly-div-3', figData, layout);
  }

  getCorrectedSignatures() {
    if (
      this.dataSheetResults &&
      this.dataSheetResults.corrected_tensile_results
    ) {
      const firstResult =
        this.dataSheetResults.corrected_tensile_results.df_results[0];
      if (firstResult) {
        return Object.keys(firstResult).filter(
          (key) =>
            ![
              'file_name',
              'datasheet',
              'nominal_age',
              'load_direction',
            ].includes(key),
        );
      }
    }

    return [];
  }

  fileChangeOnCorrectedResults(): void {
    let obj = {
      project_id: this.projectId,
      datasheet: Number(this.datasheetId),
      nominal_age: Number(this.nominalAge),
      selected_sample: this.selectedTensileSample,
      corrected_tensile_results: {},
      s_min: 20,
      s_max: 60,
      recompute_Rp02: false,
    };

    this.datasheetService.computeElasticModule(obj).subscribe({
      next: (results) => {
        if (results.correct_data) {
          this.dataSheetResults.corrected_tensile_results =
            results.correct_data;
          this.process_correctedTensileResults(
            this.dataSheetResults.tensile_files,
            results.correct_data,
          );
        }
      },
      error: (error) => {
        console.error('Error computing elastic module:', error);
        this.errorHandlerService.handleError(error);
      },
    });
  }

  computeElasticModule() {
    if (!this.correctedTensileParams) {
      this.correctedTensileParams = {};
    }
    let obj = {
      project_id: this.projectId,
      datasheet: Number(this.datasheetId),
      nominal_age: Number(this.nominalAge),
      selected_sample: this.selectedTensileSample,
      corrected_tensile_results: this.correctedTensileParams,
      s_min: this.dataSheetResults.corrected_tensile_results.s_min,
      s_max: this.dataSheetResults.corrected_tensile_results.s_max,
      recompute_Rp02: this.recomputeRp02,
    };

    this.datasheetService.computeElasticModule(obj).subscribe({
      next: (results) => {
        if (results.correct_data) {
          this.dataSheetResults.corrected_tensile_results =
            results.correct_data;
          this.process_correctedTensileResults(
            this.dataSheetResults.tensile_files,
            results.correct_data,
          );
        }
      },
      error: (error) => {
        console.error('Error computing elastic module:', error);
        this.errorHandlerService.handleError(error);
      },
    });
  }

  switchEditMode(
    rowIndex: number,
    colIndex: number,
    mode: boolean,
    signature?: string,
  ) {
    const cellKey = `${rowIndex}-${colIndex}`;
    this.isEditingCR[cellKey] = mode;
    if (mode) {
      setTimeout(() => {
        const param = `${rowIndex}-${colIndex}`;
        const inputElement = document.querySelector(
          `input.editable-input[data-param="${param}"][data-signature="${signature}"]`,
        );
        if (inputElement instanceof HTMLElement) {
          inputElement.focus();
        }
      }, 0);
    } else {
      this.generateCorrectedTensileGraph(
        this.dataSheetResults.corrected_tensile_results,
      );
    }
  }

  trackBySignature(index: number, item: any): any {
    return item;
  }

  computeLocalFit(recompute: boolean) {
    this.showTabs = false;
    this.modelGraphLoader = true;
    let scaled_residue = 0;

    if (this.modelComparisonData && this.modelResults) {
      delete this.modelComparisonData['new local fit'];
      delete this.modelResults['m_err_new'];
    }

    if (this.scaleTensileBulge) {
      scaled_residue = 1;
    }
    let obj = {
      project_id: this.projectId,
      datasheet: Number(this.datasheetId),
      nominal_age: Number(this.nominalAge),
      params: {
        excluded_tensile: this.tensileExcludedData.map(
          (file) => file.file_name,
        ),
        excluded_bulge: this.bulgeExcludedData.map((file) => file.file_name),
        model_params: this.dataSheetResults.hss_complete_params,
        gradients: {
          tensile_grad: this.tensile_grad,
          bulge_grad: this.bulge_grad,
          tensile_n_grad: this.tensile_n_grad,
          bulge_n_grad: this.bulge_n_grad,
        },
        model_weights: {
          tensile_weight: this.tensileWeight,
          bulge_weight: this.bulgeWeight,
          considere_weight: this.considereWeight,
          scaled_residue: scaled_residue,
        },
        recompute: recompute,
        tensileExcludedData: this.tensileExcludedData,
        bulgeExcludedData: this.bulgeExcludedData,
      },
    };

    this.datasheetService.computeLocalFit(obj).subscribe({
      next: (results) => {
        if (results.local_fit) {
          this.modelResults = results.local_fit;
          this.modelComparisonData = results.local_fit.df_model_params;
          this.fetchDataAndRenderPlot();
        }
      },
      error: (error) => {
        console.error('Error computing local fit:', error);
        this.modelGraphLoader = false;
        this.errorHandlerService.handleError(error);
      },
    });
  }

  async fetchDataAndRenderPlot() {
    try {
      let tensfileobj = {
        project_id: this.projectId,
        datasheet: this.datasheetId,
        nominal_age: this.nominalAge,
        file_path: this.modelResults.df_tensile_plot_path,
      };
      let bulgefileobj = {
        project_id: this.projectId,
        datasheet: this.datasheetId,
        nominal_age: this.nominalAge,
        file_path: this.modelResults.df_bulge_plot_path,
      };

      const tensfileobjResponse = await this.datasheetService
        .readCSV(tensfileobj)
        .toPromise();
      const tensileData = tensfileobjResponse.csv_data;
      this.modelResults['df_tensile_plot'] = tensileData;

      const bulgefileobjResponse = await this.datasheetService
        .readCSV(bulgefileobj)
        .toPromise();
      const bulgeData = bulgefileobjResponse.csv_data;
      this.modelResults['df_bulge_plot'] = bulgeData;
      this.showTabs = true;
      this.renderInitialGraphs();
    } catch (error) {
      console.error('Error fetching data:', error);
      this.modelGraphLoader = false;
      this.errorHandlerService.handleError(error);
    }
  }

  reset_weights() {
    this.tensileWeight = 5.0;
    this.bulgeWeight = 1.0;
    this.considereWeight = 0.005000000074505806;
    this.scaleTensileBulge = true;
  }

  renderInitialGraphs() {
    setTimeout(() => {
      this.onTabChangeStressStrain(0);
      this.onTabChangeNValues(0);
      // this.onTabChangeStressStrain(1);
      // this.onTabChangeNValues(1);
      this.modelGraphLoader = false;
    });
  }

  getTensileAndNValueTraces(df_tensile_plot: any[]): {
    traces: Partial<Plotly.Data>[];
  } {
    let tensileTraces: Partial<Plotly.Data>[] = [];
    let xdata: any = [];
    let ndata: any = [];
    let ndataSmooth: any = [];
    const uniqueFileNames = Array.from(
      new Set(df_tensile_plot.map((item) => item.file_name)),
    );

    uniqueFileNames.forEach((fileName) => {
      const samples = df_tensile_plot.filter(
        (item) => item.file_name === fileName,
      );

      xdata.push(...samples.map((item) => item.eps_pl));
      ndata.push(...samples.map((item) => item.n_value));
      ndataSmooth.push(...samples.map((item) => item.n_value_smoothed));
    });
    tensileTraces = [
      {
        x: xdata,
        y: ndata,
        type: 'scatter',
        mode: 'lines',
        name: 'Tensile n-values raw',
        line: { color: 'rgba(173,216,230,0.6)', dash: 'dot' },
        visible: 'legendonly',
      },
      {
        x: xdata,
        y: ndataSmooth,
        type: 'scatter',
        mode: 'lines',
        name: 'Tensile n-values smoothed',
        line: { color: '#0076FF' },
        visible: 'legendonly',
      },
    ];
    return {
      traces: tensileTraces,
    };
  }

  getBulgeNValueTraces(df_bulge_plot: any[]): {
    traces: Partial<Plotly.Data>[];
  } {
    let bulgeTraces: Partial<Plotly.Data>[] = [];
    const uniqueFileNames = Array.from(
      new Set(df_bulge_plot.map((item) => item.file_name)),
    );
    const xdata: any[] = [];
    const ndata: any[] = [];
    const ndataSmooth: any[] = [];
    uniqueFileNames.forEach((fileName) => {
      const samples = df_bulge_plot.filter(
        (item) => item.file_name === fileName,
      );
      xdata.push(...samples.map((item) => item.eps_sc));
      ndata.push(...samples.map((item) => item.n_value));
      ndataSmooth.push(...samples.map((item) => item.n_value_smoothed));
    });
    bulgeTraces = [
      {
        x: xdata,
        y: ndata,
        type: 'scatter',
        mode: 'lines',
        name: 'Bulge n-values raw',
        line: { color: 'rgba(128,128,128,0.8)', width: 2, dash: 'dot' },
        visible: 'legendonly',
      },
      {
        x: xdata,
        y: ndataSmooth,
        type: 'scatter',
        mode: 'lines',
        name: 'Bulge n-values smoothed',
        line: { color: '#fcbf49' },
        visible: 'legendonly',
      },
    ];
    return {
      traces: bulgeTraces,
    };
  }

  getModelNValueTraceCurrent(df_models: any): Partial<Plotly.Data>[] {
    const nValueCurrent = df_models.map((item: any) => item.n_value_current);
    const xModel = df_models.map((item: any) => item.x_model);
    const modelTrace: Partial<Plotly.Data> = {
      x: xModel,
      y: nValueCurrent,
      type: 'scatter',
      mode: 'lines',
      name: 'Curve fit - Initial',
      line: { color: '#cca3ff', width: 1.5 },
    };

    return [modelTrace];
  }

  getModelNValueTraceNew(df_models: any): Partial<Plotly.Data>[] {
    const nValueNew = df_models.map((item: any) => item.n_value_new);
    const xModel = df_models.map((item: any) => item.x_model);
    const modelTrace: Partial<Plotly.Data> = {
      x: xModel,
      y: nValueNew,
      type: 'scatter',
      mode: 'lines',
      name: 'Curve fit - New',
      line: { color: '#34a434', width: 1.5 },
    };

    return [modelTrace];
  }

  getNValueMeasurementsTrace(jsonData: any): Partial<Plotly.Data>[] {
    const dfNvalues = jsonData['df_nvalues'];
    const keys = Object.keys(dfNvalues.value);
    const nvalMeasX = keys.map((key) => dfNvalues.log_strain_avg[key]);
    const nvalMeasY = keys.map((key) => dfNvalues.value[key]);
    let color = '#ff7f0e';

    const nValueMeasurementsTrace: Partial<Plotly.Data> = {
      x: nvalMeasX,
      y: nvalMeasY,
      type: 'scatter',
      mode: 'markers',
      name: 'n-value Measurements',
      marker: {
        color: color,
        size: 20,
        symbol: 'star',
      },
    };

    return [nValueMeasurementsTrace];
  }

  getAgRmPointTrace(
    df_results: DataPoint[],
    color: string = 'maroon',
  ): Partial<Plotly.Data> {
    const meanRm =
      df_results.reduce((sum, curr) => sum + curr.Rm, 0) / df_results.length;
    const meanAg =
      df_results.reduce((sum, curr) => sum + curr.Ag, 0) / df_results.length;
    const meanE =
      df_results.reduce((sum, curr) => sum + curr.E, 0) / df_results.length;

    const s_Ag = meanRm * (1 + meanAg + meanRm / meanE);
    const e_Ag = Math.log(1 + meanAg + meanRm / meanE) - s_Ag / meanE;

    const agRmPointTrace: Partial<Plotly.Data> = {
      x: [e_Ag],
      y: [e_Ag],
      type: 'scatter',
      mode: 'markers',
      name: 'Ag/Rm Point',
      marker: {
        color: color,
        size: 20,
        symbol: 'star',
      },
    };
    return agRmPointTrace;
  }

  calculateXRange(df_models: any[]): [number, number] {
    const xModelValues = df_models.map((item) => item.x_model);
    const xMin = Math.min(...xModelValues);
    const xMax = Math.max(...xModelValues);
    return [xMin, xMax];
  }

  calculateYRange(df_models: any[], df_results: any[]): [number, number] {
    const nValueCurrent = df_models.map((item) => item.n_value_current);
    const nValueNew = df_models.map((item) => item.n_value_new);
    const RmMean =
      df_results.reduce((acc, cur) => acc + cur.Rm, 0) / df_results.length;
    const AgMean =
      df_results.reduce((acc, cur) => acc + cur.Ag, 0) / df_results.length;
    const EMean =
      df_results.reduce((acc, cur) => acc + cur.E, 0) / df_results.length;
    const yMinCalculation =
      Math.log(1 + AgMean + RmMean / EMean) -
      (RmMean * (1 + AgMean + RmMean / EMean)) / EMean;
    const yMin = 0.85 * Math.min(...nValueCurrent, yMinCalculation);
    const yMax = 1.15 * Math.max(...nValueCurrent, yMinCalculation);
    return [yMin, yMax];
  }

  getOneToOneLineTrace(
    df_models: any[],
    df_results: any[],
  ): Partial<Plotly.Data> {
    const [xMin, xMax] = this.calculateXRange(df_models);
    const [yMin, yMax] = this.calculateYRange(df_models, df_results);
    const rangeMin = Math.min(xMin, yMin);
    const rangeMax = Math.max(xMax, yMax);

    const x = Array.from(
      { length: 100 },
      (_, idx) => rangeMin + ((rangeMax - rangeMin) * idx) / 99,
    );
    return {
      x: x,
      y: x,
      type: 'scatter',
      mode: 'lines',
      line: {
        dash: 'dash',
        color: 'slategray',
      },
      name: '1:1 Line',
    };
  }

  calculateDifferences(ydata: any, xdata: any): (number | null)[] {
    if (!Array.isArray(ydata) || !Array.isArray(xdata)) {
      console.error('calculateDifferences was called with non-array arguments');
      return [];
    }
    const ydiff = ydata
      .slice(1)
      .map((current: number, i: number) => current - ydata[i]);
    const xdiff = xdata
      .slice(1)
      .map((current: number, i: number) => current - xdata[i]);
    const diffs = ydiff.map((current: number, i: number) =>
      xdiff[i] !== 0 ? current / xdiff[i] : null,
    );
    return [null, ...diffs];
  }

  getBulgeTraces(df_bulge_plot: any[]): {
    traces: Partial<Plotly.Data>[];
    xMin: number;
    xMax: number;
    yMin: number;
    yMax: number;
  } {
    let bulgeTraces: Partial<Plotly.Data>[] = [];
    const uniqueFileNames = Array.from(
      new Set(df_bulge_plot.map((item) => item.file_name)),
    );
    let xdata: any[] = [];
    let ydata: any[] = [];
    let xMin = Infinity;
    let xMax = -Infinity;
    let yMin = Infinity;
    let yMax = -Infinity;
    const dsig_deps_raw: any = [];
    const dsig_deps_smoothed: any = [];

    uniqueFileNames.forEach((fileName) => {
      const samples = df_bulge_plot.filter(
        (item) => item.file_name === fileName,
      );
      let xdata1 = [];
      let ydata1 = [];
      if (df_bulge_plot[0].eps_sc) {
        xdata1 = samples.map((item) => item.eps_sc);
        ydata1 = samples.map((item) => item.sig_sc);
      }
      if (df_bulge_plot[0].xdata) {
        xdata1 = samples.map((item) => item.xdata);
        ydata1 = samples.map((item) => item.ydata);
      }

      xdata.push(...xdata1);
      xdata.push(null);
      ydata.push(...ydata1);
      ydata.push(null);
      dsig_deps_raw.push(...this.calculateDifferences(ydata1, xdata1));
      dsig_deps_smoothed.push(...samples.map((item) => item.grad_smoothed));
    });

    bulgeTraces = [
      {
        x: xdata,
        y: ydata,
        type: 'scatter',
        mode: 'lines',
        name: 'Bulge Samples',
        visible: 'legendonly',
        line: { color: '#cca3ff', width: 2 },
      },
      {
        x: xdata,
        y: dsig_deps_raw,
        type: 'scatter',
        mode: 'lines',
        name: 'Bulge Gradient (Raw)',
        visible: 'legendonly',
        line: { color: 'rgba(128,128,128,0.8)', width: 2, dash: 'dot' },
      },
      {
        x: xdata,
        y: dsig_deps_smoothed,
        type: 'scatter',
        mode: 'lines',
        name: 'Bulge Gradient (Smoothed)',
        visible: 'legendonly',
        line: { color: 'rgba(70,130,180,0.7)', width: 2 },
      },
    ];

    xMin = Math.min(xMin, Math.min(...xdata));
    xMax = Math.max(xMax, Math.max(...xdata));
    yMin = Math.min(yMin, Math.min(...ydata));
    yMax = Math.max(yMax, Math.max(...ydata));

    return {
      traces: bulgeTraces,
      xMin: xMin === Infinity ? 0 : xMin,
      xMax: xMax === -Infinity ? 1 : xMax,
      yMin: yMin === Infinity ? 0 : yMin,
      yMax: yMax === -Infinity ? 1 : yMax,
    };
  }

  getTensileTraces(df_tensile_plot: any[]): {
    traces: Partial<Plotly.Data>[];
    xMin: number;
    xMax: number;
    yMin: number;
    yMax: number;
  } {
    let tensileTraces: Partial<Plotly.Data>[] = [];
    let xMin = Infinity;
    let xMax = -Infinity;
    let yMin = Infinity;
    let yMax = -Infinity;
    let xdata: any = [];
    let ydata: any = [];
    const dsig_deps_raw: any = [];
    const dsig_deps_smoothed: any = [];

    const uniqueFileNames = Array.from(
      new Set(df_tensile_plot.map((item) => item.file_name)),
    );

    uniqueFileNames.forEach((fileName) => {
      const samples = df_tensile_plot.filter(
        (item) => item.file_name === fileName,
      );
      let xdata1 = [];
      let ydata1 = [];
      if (df_tensile_plot[0].eps_pl) {
        xdata1 = samples.map((item) => item.eps_pl);
        ydata1 = samples.map((item) => item.sig);
      }
      if (df_tensile_plot[0].xdata) {
        xdata1 = samples.map((item) => item.xdata);
        ydata1 = samples.map((item) => item.ydata);
      }

      xdata.push(...xdata1);
      xdata.push(null);
      ydata.push(...ydata1);
      ydata.push(null);

      const currentXMin = Math.min(...xdata);
      const currentXMax = Math.max(...xdata);
      const currentYMin = Math.min(...ydata);
      const currentYMax = Math.max(...ydata);

      xMin = Math.min(xMin, currentXMin);
      xMax = Math.max(xMax, currentXMax);
      yMin = Math.min(yMin, currentYMin);
      yMax = Math.max(yMax, currentYMax);

      dsig_deps_raw.push(...this.calculateDifferences(ydata1, xdata1));
      dsig_deps_smoothed.push(...samples.map((item) => item.grad_smoothed));
    });

    tensileTraces = [
      {
        x: xdata,
        y: ydata,
        type: 'scatter',
        mode: 'lines',
        name: 'Tensile Samples',
        visible: 'legendonly',
        line: { color: 'rgba(30,144,255,0.8)', width: 2 },
      },
      {
        x: xdata,
        y: dsig_deps_raw,
        type: 'scatter',
        mode: 'lines',
        name: 'Tensile Gradient (Raw)',
        visible: 'legendonly',
        line: { color: 'rgba(173,216,230,0.6)', width: 2 },
      },
      {
        x: xdata,
        y: dsig_deps_smoothed,
        type: 'scatter',
        mode: 'lines',
        name: 'Tensile Gradient (Smoothed)',
        visible: 'legendonly',
        line: { color: 'rgba(0,0,128,0.8)', width: 2 },
      },
    ];
    return {
      traces: tensileTraces,
      xMin: xMin === Infinity ? 0 : xMin,
      xMax: xMax === -Infinity ? 1 : xMax,
      yMin: yMin === Infinity ? 0 : yMin,
      yMax: yMax === -Infinity ? 1 : yMax,
    };
  }

  getModelDataTracesCurrent(
    df_models: any,
    model_name: string,
  ): Partial<Plotly.Data>[] {
    const xModel = df_models.map((m: any) => m.x_model);
    const mCurrent = df_models.map((m: any) => m.m_current);
    const mGradCurrent = df_models.map((m: any) => m.m_grad_current);
    const modelTraces: Partial<Plotly.Data>[] = [
      {
        x: xModel,
        y: mCurrent,
        type: 'scatter',
        mode: 'lines',
        name: `${model_name} - Initial`,
        line: { color: 'rgba(255,127,80,0.9)', width: 2 },
      },
      {
        x: xModel,
        y: mGradCurrent,
        type: 'scatter',
        mode: 'lines',
        name: `${model_name} gradient - Initial`,
        line: { color: 'rgba(255,127,80,0.9)', width: 1.5, dash: 'dot' },
      },
    ];
    return modelTraces;
  }

  getModelDataTracesNew(
    df_models: any,
    model_name: string,
  ): Partial<Plotly.Data>[] {
    const xModel = df_models.map((m: any) => m.x_model);
    const mNew = df_models.map((m: any) => m.m_new);
    const mGradNew = df_models.map((m: any) => m.m_grad_new);
    const newModelTraces: Partial<Plotly.Data>[] = [
      {
        x: xModel,
        y: mNew,
        type: 'scatter',
        mode: 'lines',
        name: `${model_name} - New`,
        line: { color: '#34a434', width: 2 },
      },
      {
        x: xModel,
        y: mGradNew,
        type: 'scatter',
        mode: 'lines',
        name: `${model_name} gradient - New`,
        line: { color: '#34a434', width: 1.5, dash: 'dot' },
      },
    ];

    return newModelTraces;
  }

  getSpecialPointsTraces(
    eps_sig_pl_02: any,
    eps_sig_pl_ag: any,
    fit: string,
  ): Partial<Plotly.Data>[] {
    let color = '#ff4500';

    let specialPointsTraces: Partial<Plotly.Data>[] = [];
    specialPointsTraces.push({
      x: [eps_sig_pl_02[0]],
      y: [eps_sig_pl_02[1]],
      type: 'scatter',
      mode: 'markers',
      name: 'YS/Rp0.2',
      marker: { color: color, size: 20, symbol: 'star' },
    });

    // Rm point trace
    specialPointsTraces.push({
      x: [eps_sig_pl_ag[0]],
      y: [eps_sig_pl_ag[1]],
      type: 'scatter',
      mode: 'markers',
      name: 'UTS/Rm',
      marker: { color: color, size: 20, symbol: 'diamond' },
    });

    return specialPointsTraces;
  }

  getCurrentVSNewGraphConfigStressStrain(jsonData: any): {
    traces: Partial<Plotly.Data>[];
    layout: Partial<Plotly.Layout>;
  } {
    const bulgeData = this.getBulgeTraces(jsonData.df_bulge_plot);
    const tensileData = this.getTensileTraces(jsonData.df_tensile_plot);
    const modelTracesCurrent = this.getModelDataTracesCurrent(
      jsonData.df_models,
      'Curve fit',
    );
    const currspecialPointsTraces = this.getSpecialPointsTraces(
      jsonData.eps_sig_pl_02,
      jsonData.eps_sig_pl_ag,
      '',
    );
    const modelTracesNew = this.getModelDataTracesNew(
      jsonData.df_models,
      'Curve fit',
    );

    let allTracesCurrent = [
      ...modelTracesCurrent,
      ...modelTracesNew,
      ...currspecialPointsTraces,
      ...tensileData.traces,
      ...bulgeData.traces,
    ];

    const legenddesiredOrder = [
      'Curve fit - Initial',
      'Curve fit gradient - Initial',
      'Curve fit - New',
      'Curve fit gradient - New',
      'YS/Rp0.2',
      'UTS/Rm',
      'Tensile Samples',
      'Bulge Samples',
      'Tensile Gradient (Raw)',
      'Bulge Gradient (Raw)',
      'Tensile Gradient (Smoothed)',
      'Bulge Gradient (Smoothed)',
    ];

    const tracesdesiredOrder = [
      'YS/Rp0.2',
      'UTS/Rm',
      'Curve fit - Initial',
      'Curve fit gradient - Initial',
      'Curve fit - New',
      'Curve fit gradient - New',
      'Tensile Samples',
      'Bulge Samples',
      'Tensile Gradient (Raw)',
      'Bulge Gradient (Raw)',
      'Tensile Gradient (Smoothed)',
      'Bulge Gradient (Smoothed)',
    ];
    const sortedTraces = this.sortTraces(allTracesCurrent,legenddesiredOrder, tracesdesiredOrder);
    const layoutCurrent = this.getLayout(
      bulgeData,
      tensileData,
      'Stress vs strain plot of Initial & new hardening parameters for Hocket Sherby Swift',
    );
    return { traces: sortedTraces, layout: layoutCurrent };
  }

  sortTraces(traces: any[], legenddesiredOrder: string[], tracesdesiredOrder: string[]): any[] {
    const orderMap = new Map(tracesdesiredOrder.map((name, index) => [name, index]));
    const legendOrderMap = new Map(legenddesiredOrder.map((name, index) => [name, index]));

    // Sort traces by desired order
    const sortedTraces = traces.sort((a, b) => {
      const indexA = orderMap.get(a.name) ?? tracesdesiredOrder.length;
      const indexB = orderMap.get(b.name) ?? tracesdesiredOrder.length;
      return indexA - indexB;
    });
  
    // Update the legend order by assigning 'legendrank'
    sortedTraces.forEach(trace => {
      trace.legendrank = legendOrderMap.get(trace.name) ?? legenddesiredOrder.length; 
    });
  
    // Reverse the array to fix layering issue (top traces are drawn last)
    return sortedTraces.reverse();  // Ensures that the top trace appears last in rendering
  }
  
  getCurrentGraphConfigStressStrain(jsonData: any): {
    traces: Partial<Plotly.Data>[];
    layout: Partial<Plotly.Layout>;
  } {
    this.bulge_plot_data;
    const bulgeData = this.getBulgeTraces(this.bulge_plot_data);
    const tensileData = this.getTensileTraces(this.tensile_plot_data);
    const modelTracesCurrent = this.getModelDataTracesCurrent(
      jsonData.df_models,
      'Curve fit',
    );
    const currspecialPointsTraces = this.getSpecialPointsTraces(
      jsonData.eps_sig_pl_02,
      jsonData.eps_sig_pl_ag,
      'Initial fit',
    );

    const allTracesCurrent = [
      ...modelTracesCurrent,
      ...currspecialPointsTraces,
      ...bulgeData.traces,
      ...tensileData.traces,
    ];

    const layoutCurrent = this.getLayout(
      bulgeData,
      tensileData,
      'Stress/strain plot of current hardening parameters for Hocket Sherby Swift',
    );

    return { traces: allTracesCurrent, layout: layoutCurrent };
  }

  getLayout(
    bulgeData: any,
    tensileData: any,
    title: string,
  ): Partial<Plotly.Layout> {
    const combinedXMin = Math.min(bulgeData.xMin, tensileData.xMin);
    const combinedXMax = Math.max(bulgeData.xMax, tensileData.xMax);
    const combinedYMin = Math.min(bulgeData.yMin, tensileData.yMin);
    const combinedYMax = Math.max(bulgeData.yMax, tensileData.yMax);

    return {
      xaxis: { title: 'Strain', range: [combinedXMin, combinedXMax] },
      yaxis: { title: 'Stress', range: [combinedYMin, combinedYMax] },
      legend: { x: 1, y: 1 },
      title: title,
    };
  }

  onTabChangeStressStrain(input: number | MatTabChangeEvent) {
    let tabIndex: number;
    if (typeof input === 'number') {
      tabIndex = input;
    } else {
      tabIndex = input.index;
    }

    if (tabIndex === 0) {
      const { traces, layout } = this.getCurrentVSNewGraphConfigStressStrain(
        this.modelResults,
      );
      this.renderPlot('plotDiv1', traces, layout);
    }
  }

  onTabChangeNValues(input: number | MatTabChangeEvent) {
    let tabIndex: number;
    if (typeof input === 'number') {
      tabIndex = input;
    } else {
      tabIndex = input.index;
    }

    if (tabIndex === 0) {
      const { traces, layout } = this.getCurrentVSNewNValuePlotConfig(
        this.modelResults,
      );
      this.renderPlot('plotDiv3', traces, layout);
    }
  }

  renderPlot(divId: string, data: any[], layout: any) {
    const element = document.getElementById(divId);
    if (element) {
      Plotly.newPlot(divId, data, layout);
    }
  }

  getCurrentVSNewNValuePlotConfig(jsonData: any): {
    traces: Partial<Plotly.Data>[];
    layout: Partial<Plotly.Layout>;
  } {
    const tensileData = this.getTensileAndNValueTraces(
      jsonData.df_tensile_plot,
    );
    const bulgeData = this.getBulgeNValueTraces(jsonData.df_bulge_plot);
    const modelDataTraceCurrent = this.getModelNValueTraceCurrent(
      jsonData.df_models,
    );
    const nValueMeasurementsTrace = this.getNValueMeasurementsTrace(jsonData);
    const agRmPointTrace = this.getAgRmPointTrace(jsonData.df_results);
    const modelDataTraceNew = this.getModelNValueTraceNew(jsonData.df_models);
    const oneToOneLineTrace = this.getOneToOneLineTrace(
      jsonData.df_models,
      jsonData.df_results,
    );

    const combinedTracesCurrent = [
      ...modelDataTraceCurrent,
      ...modelDataTraceNew,
      ...nValueMeasurementsTrace,
      agRmPointTrace,
      oneToOneLineTrace,
      ...tensileData.traces,
      ...bulgeData.traces,
    ];

    const layoutCurrent: Partial<Plotly.Layout> = {
      title:
        'N-value plot of initial & new hardening parameters for Hocket Sherby Swift',
      xaxis: {
        title: 'X Model',
        range: this.calculateXRange(jsonData.df_models),
      },
      yaxis: {
        title: 'Y Model',
        range: this.calculateYRange(jsonData.df_models, jsonData.df_results),
      },
      legend: { orientation: 'v' },
    };

    const tracesdesiredOrder = [
      'n-value Measurements',
      'Ag/Rm Point',
      'Curve fit - Initial',
      'Curve fit - New',
      'Tensile n-values raw',
      'Bulge n-values raw',
      'Tensile n-values smoothed',
      'Bulge n-values smoothed',
      '1:1 Line',
    ];
    
    const legenddesiredOrder = [
      'Curve fit - Initial',
      'Curve fit - New',
      'n-value Measurements',
      'Ag/Rm Point',
      'Tensile n-values raw',
      'Bulge n-values raw',
      'Tensile n-values smoothed',
      'Bulge n-values smoothed',
      '1:1 Line',
    ];
    const sortedTraces = this.sortTraces(combinedTracesCurrent, legenddesiredOrder, tracesdesiredOrder);
    return { traces: sortedTraces, layout: layoutCurrent };
  }

  getCurrentNValuePlotConfig(jsonData: any): {
    traces3: Partial<Plotly.Data>[];
    layout3: Partial<Plotly.Layout>;
  } {
    const modelDataTraceCurrent = this.getModelNValueTraceCurrent(
      jsonData.df_models,
    );
    const nValueMeasurementsTrace = this.getNValueMeasurementsTrace(jsonData);
    const modelDataTraceNew = this.getModelNValueTraceNew(jsonData.df_models);

    const combinedTracesCurrent = [
      ...modelDataTraceCurrent,
      ...nValueMeasurementsTrace,
      // agRmPointTrace,
      // ...tensileData.traces,
      // ...bulgeData.traces,
    ];

    const layoutCurrent: Partial<Plotly.Layout> = {
      title:
        'N-value plot of current hardening parameters for Hocket Sherby Swift',
      xaxis: {
        title: 'X Model',
        range: this.calculateXRange(jsonData.df_models),
      },
      yaxis: {
        title: 'Y Model',
        range: this.calculateYRange(jsonData.df_models, jsonData.df_results),
      },
      legend: { orientation: 'v' },
    };

    return { traces3: combinedTracesCurrent, layout3: layoutCurrent };
  }

  // getCellValue(signature: string, param: string): any {
  //   return (
  //     this.dataSheetResults.hss_complete_params?.[signature]?.[param] ?? ''
  //   );
  // }
  getCellValue(signature: string, param: string): any {
    const value = this.dataSheetResults.hss_complete_params?.[signature]?.[param] ?? '';

    if (typeof value === 'number') {
      const valueStr = value.toString();
      const decimalIndex = valueStr.indexOf('.');
      if (decimalIndex !== -1) {
        const decimalPlaces = valueStr.length - decimalIndex - 1; 
        if (decimalPlaces > 9) {
          return parseFloat(value.toFixed(9)); 
        }
      }
      return value;
    }
    return value; 
  }



  setCellValue(signature: string, param: string, value: any): void {
    if (!this.dataSheetResults.hss_complete_params[signature]) {
      this.dataSheetResults.hss_complete_params[signature] = {};
    }
    if (param === 'param_variable') {
      this.dataSheetResults.hss_complete_params[signature][param] =
        value === 'true' ? true : false;
      return;
    }
    if (value == '') {
      this.snackBarNotificationService.openSnackBar(
        'Input cannot be empty,preserving previous value.',
        'error',
      );
      return;
    }
    if (!this.isNumeric(value)) {
      this.snackBarNotificationService.openSnackBar(
        'Input must be numeric.',
        'error',
      );
      return;
    }
    this.dataSheetResults.hss_complete_params[signature][param] =
      parseFloat(value);
  }

  getRoundedValue(value: any, signature: string): any {
    const digits = this.roundingRules[signature];
    return digits !== undefined ? parseFloat(value.toFixed(digits)) : value;
  }

  getValueCorrectedTensile(rowIndex: number, signature: string): any {
    const result =
      this.dataSheetResults.corrected_tensile_results.df_results[rowIndex];
    if (
      !result ||
      result[signature] === null ||
      result[signature] === undefined
    ) {
      return null;
    }
    const value = result[signature];
    const digits = this.roundingRules[signature];
    return digits !== undefined ? parseFloat(value.toFixed(digits)) : value;
  }

  setValueCorrectedTensile(
    rowIndex: number,
    signature: string,
    value: any,
  ): void {
    if (value == null) {
      this.snackBarNotificationService.openSnackBar(
        'Input cannot be empty,preserving previous value.',
        'error',
      );
      return;
    }
    if (!this.isNumeric(value)) {
      this.snackBarNotificationService.openSnackBar(
        'Input must be numeric.',
        'error',
      );
      return;
    }
    const numericValue = Number(value);
    const digits = this.roundingRules[signature];
    const roundedValue =
      digits !== undefined
        ? parseFloat(numericValue.toFixed(digits))
        : numericValue;
    let result =
      this.dataSheetResults.corrected_tensile_results.df_results[rowIndex];
    if (result) {
      result[signature] = roundedValue;
      this.correctedTensileParams = result;
    }
  }

  isNumeric(value: any): boolean {
    return /^-?\d+\.?\d*$/.test(value);
  }

  getKeys(obj: any): string[] {
    return Object.keys(obj);
  }

  getFitValue(item: string, key: string): any {
    return this.modelComparisonData[item][key];
  }

  getErrorValue(key: string): any {
    return this.modelResults[key];
  }

  onDirectorySelected(event: any) {
    const directory = event.target.files;
    this.selectedFolder = directory[0].webkitRelativePath.split('/')[0];

    this.formData = new FormData();
    for (let i = 0; i < directory.length; i++) {
      this.formData.append('files', directory[i], directory[i].name);
      this.formData.append('filePaths', directory[i].webkitRelativePath);
    }
  }

  uploadDirectory() {
    this.uploadLoader = true;
    const siteId = '1';
    this.datasheetService
      .ingestData(this.formData, siteId, this.projectId)
      .subscribe({
        next: (result) => {},
        error: (error) => {
          this.toaster.error('Folder not uploaded', '', {
            positionClass: 'custom-toast-position',
          });
        },
        complete: () => {
          this.getDataSheetSource();
          this.uploadLoader = false;
          this.toaster.success('Folder Uploaded Successfully', '', {
            positionClass: 'custom-toast-position',
          });
        },
      });
  }

  saveExcludedFile(
    option: string,
    reason: string,
    index: number,
    type: string,
  ) {
    if (type === 'tensile') {
      const existingFileIndex = this.tensileExcludedData.findIndex(
        (item) => item.file_name === option,
      );
      if (existingFileIndex !== -1) {
        this.tensileExcludedData[existingFileIndex].reason = reason;
      } else {
        this.tensileExcludedData.push({ file_name: option, reason: reason });
      }
      this.tensileRecentlyChangedFiles.delete(option);
    } else if (type === 'bulge') {
      const existingFileIndex = this.bulgeExcludedData.findIndex(
        (item) => item.file_name === option,
      );
      if (existingFileIndex !== -1) {
        this.bulgeExcludedData[existingFileIndex].reason = reason;
      } else {
        this.bulgeExcludedData.push({ file_name: option, reason: reason });
      }
      this.bulgeRecentlyChangedFiles.delete(option);
    }
  }

  removeExcludedFile(option: string, type: string) {
    if (type === 'tensile') {
      const index = this.tensileExcludedData.findIndex(
        (data) => data.file_name === option,
      );
      if (index !== -1) {
        this.tensileExcludedData.splice(index, 1);
      }
    } else {
      const index = this.bulgeExcludedData.findIndex(
        (data) => data.file_name === option,
      );
      if (index !== -1) {
        this.bulgeExcludedData.splice(index, 1);
      }
    }
  }

  onInputChange(fileName: string, type: string): void {
    if (type == 'tensile') {
      this.tensileRecentlyChangedFiles.add(fileName);
    } else {
      this.bulgeRecentlyChangedFiles.add(fileName);
    }
  }

  navigate(pageName: string) {
    let selectedProjectId: string | undefined =
      this.configService.SelectedProjectId;
    if (!selectedProjectId) {
      return;
    }
    const projectId = selectedProjectId;
    const siteId = this.configService.SelectedSiteId;
    this.router.navigate([`sites/${siteId}/projects/${projectId}/${pageName}`]);
  }
}
