<div fxLayout="column" class="h-100">
  <div
    fxFlex="none"
    fxLayout="row"
    fxLayoutAlign="space-between center"
    class="border-bottom p-6"
  >
    <div>
      <h1>Data visualization</h1>
    </div>
    <div>
      <button mat-icon-button [mat-dialog-close]="true">
        <mat-icon class="close-icon">close</mat-icon>
      </button>
    </div>
  </div>
  <div fxFlex class="y-auto">
    <ng-container *ngIf="data.plots.length > 0">
      <div fxLayout="row" style="width: 100%; height: 100%;" *ngFor="let plot of data.plots; let i = index">
        <object type="text/html" [data]="plot" class="interactive-graph" style="width: 100%;"></object>
      </div>
    </ng-container>  
  </div>
</div>
