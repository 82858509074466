<div class="global-float-button">
  <div fxLayout="row" fxLayoutGap="16px">
    <div>
      <mat-icon 
        *ngIf="checkForTheRunStatus()"
        matTooltip="Preview"
        [matMenuTriggerFor]="filterMlWidgets.length>1 ? previewMenu : null"
        class="material-symbols-outlined cursor-pointer"
        (click)="filterMlWidgets.length>1 ? null : onPreviewDataButton()"
        >preview</mat-icon
      >
      <mat-menu #previewMenu="matMenu">
        <button mat-menu-item (click)="onMLPreviewData('single')">Preview selected AI/ML widget</button>
        <button mat-menu-item (click)="onMLPreviewData('multiple')">Preview all AI/ML widgets</button>
      </mat-menu>
    </div>
    <div>
      <mat-icon
        *ngIf="!this.workflowCanvasService.IsVersionedWorkflow"
        matTooltip="Execute Widget"
        class="material-symbols-outlined cursor-pointer"
        (click)="onExecuteWidgetClicked()"
        >bolt</mat-icon
      >
    </div>
    <!-- <div>
      <mat-icon class="material-symbols-outlined">more_vert</mat-icon>
    </div> -->
    <div class="mt-1 fw-bold">{{ getWidgetName() }}</div>
  </div>
</div>
<app-notification #notification></app-notification>
