<div fxLayout="column" class="h-100">
  <div fxFlex="none">
    <app-header></app-header>
  </div>
  <div fxFlex fxLayout="row">
    <div fxFlex="none">
      <app-left-nav></app-left-nav>
    </div>
    <div fxFlex>
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
