<div [style.paddingLeft.px]="level * 20">
  <div
    (click)="onToggle()"
    class="border-bottom tree-node-row"
    fxLayout="row"
    fxLayoutAlign="start center"
    fxLayoutGap="8px"
  >
    <div fxFlex="none">
      <mat-icon *ngIf="hasChildren || !isFilePath(path)">{{
        isExpanded ? "expand_more" : "chevron_right"
      }}</mat-icon>
    </div>
    <div fxFlex="none">
      <mat-icon class="material-symbols-outlined text-grey">
        <ng-container *ngIf="!isFilePath(path); else templateName"
          >folder</ng-container
        >
        <ng-template #templateName> draft </ng-template>
      </mat-icon>
    </div>
    <div fxFlex>
      {{ path.split("/").pop() }}
    </div>
    <div fxFlex="none">
      <button
        mat-icon-button
        [matMenuTriggerFor]="deleteMenu"
        color="primary"
        (click)="stopPropagation($event)"
        *ngIf="level == 0"
      >
        <mat-icon class="material-symbols-outlined">delete</mat-icon>
      </button>
    </div>
  </div>
  <div *ngIf="isExpanded">
    <app-file-tree-node
      *ngFor="let child of children; trackBy: trackByFn"
      [path]="path + '/' + child"
      [level]="level + 1"
      [children]="childrenData[path + '/' + child]"
      [isExpanded]="expandedNodes[path + '/' + child]"
      (toggle)="toggle.emit($event)"
      (delete)="delete.emit($event)"
      [expandedNodes]="expandedNodes"
      [childrenData]="childrenData"
      [isFilePath]="isFilePath"
    ></app-file-tree-node>
  </div>
</div>

<mat-menu class="global-menu" #deleteMenu="matMenu">
  <div mat-menu-item class="header">Are you sure?</div>
  <button mat-menu-item (click)="confirmDelete()">
    <mat-icon>check</mat-icon> Yes
  </button>
  <button mat-menu-item><mat-icon>close</mat-icon> No</button>
</mat-menu>
