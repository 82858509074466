<div fxLayout="column" class="h-100">
  <div fxFlex="none"><app-header></app-header></div>
  <div fxFlex fxLayout="column" fxLayoutGap="24px" class="h-100 p-8">
    <div fxFlex="none">
      <h1 class="global-main-title">Projects</h1>
    </div>
    <div fxFlex="none" fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign=" center" fxHide.lt-sm>
        <div fxFlex="220px" fxFlex.lt-md="150px">
          <mat-form-field appearance="outline">
            <mat-icon matPrefix>search</mat-icon>
            <input matInput placeholder="Search" [(ngModel)]="searchText"
              (ngModelChange)="onSearchTextChange($event)" />
          </mat-form-field>
        </div>
      </div>
      <div *ngIf="role_id == 1 || role_id_sso == 1 || role_id == 2 || role_id_sso == 2">
        <button mat-flat-button color="primary" (click)="createNewProject()"
          (closePanelClicked)="onCloseCreateNewProjectPanel()">
          <mat-icon class="material-symbols-outlined">add</mat-icon>
          Create <span fxHide.lt-md>Project</span>
        </button>
      </div>
    </div>
    <div fxFlex class="y-auto">
      <div *ngIf="loadAllProjects.length === 0 && areProjectsLoaded" class="global-nodata centered-info-message">
        No Projects have been created
      </div>
      <table mat-table [dataSource]="projectsList.filteredData" matSort (matSortChange)="sortData($event)" *ngIf="loadAllProjects.length != 0 && areProjectsLoaded"
        class="global-table">
        <ng-container *ngIf="projectsList.filteredData.length === 0">
          <tr mat-row>
            <td colspan="5">No projects match the filter criteria</td>
          </tr>
        </ng-container>
        <ng-container matColumnDef="favorite">
          <th mat-header-cell *matHeaderCellDef width="40">&nbsp;</th>
          <td mat-cell *matCellDef="let element">
            <mat-icon style="cursor: pointer" [ngClass]="
            checkFavStatus(element) ? 'text-primary' : 'text-grey'" (click)="toggleSelection(element)">
              {{ checkFavStatus(element) ? "star" : "star_outline" }}
            </mat-icon>
          </td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
          <td mat-cell *matCellDef="let element" (click)="onProjectRowSingleClick(element)">
            {{ element.project_name ? element.project_name : "Unknown" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="owner">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Created By</th>
          <td mat-cell *matCellDef="let element" (click)="onProjectRowSingleClick(element)">
            {{ element.project_owner_name ? element.project_owner_name : "Unknown" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
          <td mat-cell *matCellDef="let element" (click)="onProjectRowSingleClick(element)">
            {{ element.description ? element.description : "Unknown" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="created_at">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Date Created&nbsp;<span class="text-sm text-grey ws-nowrap">(Local
              Time)</span></th>
          <td mat-cell *matCellDef="let element" (click)="onProjectRowSingleClick(element)">
            {{ lastAccessedDate(element.created_at) | date: "dd-MMM-yyyy h:mm a" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="lastModified">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Access&nbsp;<span class="text-sm text-grey ws-nowrap">(Local
              Time)</span></th>
          <td mat-cell *matCellDef="let element" (click)="onProjectRowSingleClick(element)">
            {{ lastAccessedDate(element.last_accessed_at) | date: "dd-MMM-yyyy h:mm a" }}
          </td>
        </ng-container>
        <ng-container matColumnDef="more_vert">
          <th mat-header-cell *matHeaderCellDef width="40">&nbsp;</th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button (click)="deleteConfirmation = false" [matMenuTriggerFor]="actionsMenu"
              [matMenuTriggerData]="{ rowData: element }">
              <mat-icon>more_vert</mat-icon>
            </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" [class.selected]="isProjectRowSelected(row)"></tr>
      </table>
    </div>
  </div>
</div>

<mat-menu #actionsMenu="matMenu" class="global-menu">
  <ng-template matMenuContent let-rowData="rowData">
    <ng-container *ngIf="!deleteConfirmation">
      <button mat-menu-item (click)="onViewProject(rowData)">
        <mat-icon class="material-symbols-outlined">visibility</mat-icon> View
      </button>
      <button mat-menu-item (click)="updateProject(rowData)"
        *ngIf="isProjectAdministrator() || role_id == 1 || role_id_sso == 1">
        <mat-icon class="material-symbols-outlined">edit</mat-icon> Edit
      </button>
      <button mat-menu-item (click)="deleteConfirmation = true; $event.stopPropagation(); openConfirmationDialog()"
        *ngIf="isProjectAdministrator() || role_id == 1 || role_id_sso == 1">
        <mat-icon class="material-symbols-outlined">delete</mat-icon> Delete
      </button>
    </ng-container>
    <!-- <ng-container *ngIf="deleteConfirmation">
      <div mat-menu-item class="header">Are You Sure? This will delete all associated Workflows, Datasets, Models and Widgets.</div>
      <button mat-menu-item (click)="DeleteProject(rowData)"><mat-icon>check</mat-icon> Yes</button>
      <button mat-menu-item><mat-icon>close</mat-icon> No</button>
    </ng-container> -->
    <ng-template #confirmationDialogTemplate>
      <!-- <h1 mat-dialog-title>Project Delete Confirmation</h1> -->
      <div mat-dialog-content>
        <p>Are You Sure? This will delete all associated Workflows, Datasets, Models and Widgets.</p>
      </div>
      <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px">
        <button mat-flat-button (click)="onCancel()">Cancel</button>
        <button mat-flat-button color="primary" (click)="DeleteProject(rowData)">Confirm</button>
      </div>
    </ng-template>
  </ng-template>

</mat-menu>