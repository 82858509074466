import { ChangeDetectorRef, Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfigService, WorkflowCanvasService } from 'src/app/pages/workflow-designer/workflow-canvas.service';
import { WidgetControl } from 'src/app/controls/widget-control/widget-control';
import {
  Widget,
  DataCopyWidgetConfig,
  LocalFileConfiguration,
  WidgetType,
  SourceType,
  BigQueryDatasetConfiguration,
  MountedDriveConfiguration,
  DatasetModel,
} from 'src/app/models/workflow-models';
import { BigQueryConfigComponent } from 'src/app/controls/configs/big-query-config/big-query-config.component';
import { DataPreviewService } from './services/data-preview.service';
import { DomSanitizer } from '@angular/platform-browser';
import { DataPreviewDataComponent } from './data/data.component';

@Component({
  selector: 'app-data-preview',
  templateUrl: './data-preview.component.html',
  styleUrls: ['./data-preview.component.less'],
})
export class DataPreviewComponent {
  @ViewChild(DataPreviewDataComponent) dataPreviewDataComponent!: DataPreviewDataComponent;

  public widgetControl: WidgetControl | undefined = undefined;
  config: DataCopyWidgetConfig | undefined = undefined;
  datasetId: string | undefined = '';
  datasetIds: DatasetModel[] = [];
  activeTab: number = 0;
  isPNG: boolean= false;
  resultPNG: any;
  UC2SigmaDataPreviewPath: any;
  isDatasetDropdown = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<DataPreviewComponent>,
    private editWorkflowService: WorkflowCanvasService,
    private sanitizer: DomSanitizer,
    private configService: ConfigService,
    private cdr: ChangeDetectorRef
  ) {
    if (!this.editWorkflowService.selectedWidgetControl) {
      return;
    }
    this.widgetControl = this.editWorkflowService.selectedWidgetControl;
    this.config = this.widgetControl.Widget.config as DataCopyWidgetConfig;
  }

  ngOnInit() {

    if (typeof this.data.datasetId === 'string') { 
      this.datasetId = this.data.datasetId;
    } else if (Array.isArray(this.data.datasetId)) {
      this.datasetIds = this.data.datasetId;
      this.datasetId = this.datasetIds[0].id;
      // can change it enable only if more than one dataset is there in the list
      this.isDatasetDropdown = true;
    }

    this.checkPNG();
    if(this.data.uc2SigmaFilePath) {
      this.UC2SigmaDataPreviewPath = this.data.uc2SigmaFilePath
        this.activeTab = 1;
        this.cdr.detectChanges();
    }
  }

  onDatasetChange() {
    this.activeTab = 0;
  }

  checkPNG(){
    if(this.datasetId && this.data.isPNG){
      this.isPNG = this.data.isPNG;
      this.resultPNG = this.sanitizer.bypassSecurityTrustResourceUrl(`${this.configService.getAppAuxApiURL}/eda/file?path=${this.data.full_path}`);
    }
  }

   refreshData() {
    if (this.dataPreviewDataComponent) {
      this.dataPreviewDataComponent.refreshData();
    }
  }

}
