import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-file-tree-node',
  templateUrl: './file-tree-node.component.html',
  styleUrls: ['./file-tree-node.component.less'],
})
export class FileTreeNodeComponent implements OnInit {
  @Input() path: string = '';
  @Input() level: number = 0;
  @Input() children: any[] = [];
  @Input() isExpanded: boolean = false;
  @Input() expandedNodes: { [key: string]: boolean } = {};
  @Input() childrenData: { [key: string]: any[] } = {};
  @Input() isFilePath!: (path: string) => boolean;

  @Output() toggle = new EventEmitter<string>();
  @Output() delete = new EventEmitter<string>();

  public hasChildren: boolean = false;

  ngOnInit() {
    this.hasChildren =
      this.children && this.children.length > 0 && !this.isFilePath(this.path);
  }

  onToggle() {
    this.toggle.emit(this.path);
  }

  trackByFn(index: number, item: any) {
    return item;
  }

  stopPropagation(event: Event) {
    event.stopPropagation();
  }

  confirmDelete() {
    this.delete.emit(this.path);
  }
}
