<div fxLayout="row" fxLayoutAlign="space-between center">
    <h1>{{ title }}</h1>
    <button mat-icon-button (click)="close()">
      <mat-icon>close</mat-icon>
    </button>
</div>
<!-- <div *ngIf="tableData == false">
    <pre>{{ result | json }}</pre>
</div> -->

<div>
    <div class="mat-table">
        <table mat-table [dataSource]="dataSource" class="mat-table" multiTemplateDataRows>
          
          <!-- Dynamic Column Definition -->
          <ng-container [matColumnDef]="column" *ngFor="let column of displayedColumns">
            <th mat-header-cell *matHeaderCellDef>{{ column }}</th>
            <td mat-cell *matCellDef="let element">{{ element[displayedColumns.indexOf(column)] }}</td>
          </ng-container>
      
          <!-- Header Row -->
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <!-- Data Rows -->
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </div>
</div>
