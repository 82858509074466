export const environment = {
  production: false,
  // instance urls
  // apiUrl:"https://workflow-api.corp.multiscale.tech",
  // auxApiUrl:"https://workflow-api.corp.multiscale.tech",
  // local urls
  apiUrl:"https://workflow-api.corp.multiscale.tech",
  auxApiUrl:"https://workflow-api.corp.multiscale.tech",
  // note: tdamUrl is specifically for proxy backend server that can handle shared drive
  // post merging the changes we can remover this url and use apiUrl itself
  tdamUrl: 'https://mindtsldatabe.corp.multiscale.tech',
  // tdamUrl: 'http://10.0.5.47:8071',
  dbUrl:"localhost:27017",
  clientId: '763bafb9-ab37-4553-a962-a307f942a1ed',
  authority: 'f396221c-bd8f-4669-a89b-cb4fa9a5b422',
  redirectUri: 'https://web.corp.multiscale.tech/',
  mind2apiUrl:"https://workflow-api.corp.multiscale.tech",
  mind2Urlext:"https://web.corp.multiscale.tech",
  mind3Urlext:"https://web.corp.multiscale.tech",
  mbapiUrl:"https://mlflowmb.corp.multiscale.tech",
};

export const authConfig = {
  production: false,
  authConfig: {
    issuer: 'https://accounts.google.com',
    clientId:
      '721024859117-m0ejk8eubgl8c5tm5f13k7dki2a9mo64.apps.googleusercontent.com',
    redirectUri: 'https://web.corp.multiscale.tech/login',
    scope: 'openid profile email',
    responseType: 'token id_token',
    strictDiscoveryDocumentValidation: false,
    loginUrl: 'https://accounts.google.com/o/oauth2/v2/auth',
    tokenEndpoint: 'https://oauth2.googleapis.com/token',
    userinfoEndpoint: 'https://openidconnect.googleapis.com/v1/userinfo',
  },
};
