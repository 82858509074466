import { ChangeDetectorRef, Component } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import { Router } from '@angular/router';
import { ApiService } from 'src/app/services/api.service';
import { ConfigService } from 'src/app/services/config.service';
import { TimeAgoPipe } from 'src/app/time-ago.pipe';

@Component({
  selector: 'app-notification-dailog',
  templateUrl: './notification-dailog.component.html',
  styleUrls: ['./notification-dailog.component.less']
})
export class NotificationDailogComponent {
  user: any;
  notificationstatus: any;
  notifications: any[] = [];
  notificationCount: number = 4;
  project_id: any;
  
  constructor(
    public dialogRef: MatDialogRef<NotificationDailogComponent>,
    private configService: ConfigService, private router: Router,
    private apiService: ApiService,
    private cdr: ChangeDetectorRef
  ) {
    this.user = localStorage.getItem('currentUser');
    this.user = JSON.parse(this.user);
    this.project_id = this.configService.SelectedProjectId;
  }


  close(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.loadnotifications(undefined);
  }

  async loadnotifications(searchTerms: string | undefined) {
    try {
      this.notifications = await this.apiService.GetNotifications("1", this.project_id, searchTerms);
      if (this.notifications.length > 0) {
        this.notificationstatus = this.notifications[this.notifications.length - 1].read;
      } else {
        this.notificationstatus = undefined;
      }
      this.cdr.detectChanges();
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  }

  getUnreadNotificationCount(): number {
    return this.notifications.filter((notification) => !notification.read.includes(this.user._id))
      .length;
  }

  async markAllNotifucationAsread() {
    try {
      let response;
      response = await this.apiService.UpdateAllNotificationStatus(
        '1',
        this.user._id
      );
      if (response) {
        this.loadnotifications(undefined);
        this.apiService.updateNotificationStatus();
        setTimeout(() => {
          this.apiService.updateNotificationCount(this.getUnreadNotificationCount())
        }, 2000);
      } else {
        console.error('Notifications update failed');
      }
    } catch (error) {
      console.error('An error occurred during notifications update:', error);
    }
  }

  navigate(pageName: string, assetType: string | undefined = undefined) {
    let selectedProjectId: string | undefined = this.configService.SelectedProjectId;
    if (!selectedProjectId) {
      return;
    }

    const siteId = this.configService.SelectedSiteId;
    this.router.navigate([
      `sites/${siteId}/projects/${selectedProjectId}/${pageName}`,
    ]);
    this.close();
  }
}
