 
<div class="container">
    <div class="centered-content">
        <img src="assets/logo.png" width="500" alt="Multiscale Mind" class="logo">
        <label style="font-size: 30px;color:#003DE7;font-family: 'SourceSansProExtraBlack';">Change Password</label>
       
        <div class="change-password-container">
            <div class="password-container">
                <input 
                  [(ngModel)]="oldPassword"
                  name="oldPassword"
                  [type]="oldPasswordVisible ? 'text' : 'password'" 
                  placeholder="Enter Password" 
                  class="input-field"
                >
                <i 
                  class="fa-regular fa-eye toggle-icon" 
                  (click)="togglePasswordVisibility()"
                ></i>
            </div>
            <div class="password-container">
                <input 
                  [(ngModel)]="newPassword"
                  name="newPassword"
                  [type]="newPasswordVisible ? 'text' : 'password'" 
                  placeholder="Enter Password" 
                  class="input-field"
                >
                <i 
                  class="fa-regular fa-eye toggle-icon" 
                  (click)="toggleNewPasswordVisibility()"
                ></i>
            </div>            
            <div class="password-container">
                <input 
                  [(ngModel)]="newPasswordRepeat"
                  name="newPasswordRepeat"
                  [type]="newPasswordRepeatVisible ? 'text' : 'password'" 
                  placeholder="Enter Password" 
                  class="input-field"
                >
                <i 
                  class="fa-regular fa-eye toggle-icon" 
                  (click)="toggleNewPasswordRepeatVisibility()"
                ></i>
            </div>            
            
            
            <div class="button-container">
                <button [disabled]="changingPassword" class="change-password-button change-password-button-cancel" (click)="onCancel()">                    
                    Cancel
                </button>
                <button [disabled]="changingPassword" class="change-password-button change-password-button-change " (click)="onChangePassword()">
                    <i class="fa-solid fa-lock" style="margin-right:10px"></i>
                    Change Password
                    <div *ngIf="changingPassword" class="spinner"></div>
                </button>
                
            </div>            
        </div>
      
    </div>    
</div>
<app-notification #notification></app-notification>