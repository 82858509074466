import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'scientificNumber',
})
export class ScientificNumberPipe implements PipeTransform {
  transform(value: number, forceScientific: boolean = false): string {
    if (value === undefined || value === null) {
      return 'null';
    }

    if (typeof value !== 'number' || isNaN(value)) {
      return value.toString(); // Return the original value if it's not a number
    }

    if (value === 0) {
      return '0';
    }

    if (forceScientific) {
      return this.formatScientific(value.toExponential(3));
    }
  
    if (value < 1_000_000_000) {
      if (Number.isInteger(value)) {
        return value.toString();
      }
      if (value < 100_000) {
        if (value <= 0.00001) {
          return this.formatScientific(value.toExponential(2));
        }
        return parseFloat(value.toFixed(4)).toString();
      } else {
        return parseFloat(value.toFixed(2)).toString();
      }
    }
    return this.formatScientific(value.toExponential(4)).toString();
  }

  private formatScientific(exponentialStr: string): string {
    const [coefficient, exponent] = exponentialStr.split('e');
    const formattedExponent = this.convertToSuperscript(exponent.replace('+', ''));
    return `${coefficient} &times; 10${formattedExponent}`;  }

  private convertToSuperscript(exponent: string): string {
    const superscriptMap: { [key: string]: string } = {
      '0': '⁰', '1': '¹', '2': '²', '3': '³', '4': '⁴',
      '5': '⁵', '6': '⁶', '7': '⁷', '8': '⁸', '9': '⁹',
      '-': '⁻' 
    };
    return exponent.split('').map(char => superscriptMap[char] || char).join('');
  }
}