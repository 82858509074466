<div fxLayout="column" class="dc-container h-100">
  <div fxFlex="none" fxLayout="row" fxLayoutAlign="space-between center" class="p-6 border-bottom">
    <div>
      <h1>Sigma Common Test Id(s)</h1>
    </div>
    <div>
      <button mat-icon-button (click)="onClose()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <div fxFlex fxLayout="row" fxLayoutGap="24px" class="p-6 y-auto">
    <div fxFlex fxLayout="column" fxLayoutGap="8px">
      <div fxFlex fxLayout="column" fxLayoutGap="4px" class="y-auto">
        <div fxLayout="row" *ngFor="let row of rows; let i = index" fxLayoutGap="8px" fxLayoutAlign="start center">
          <div fxFlex class="multiple-select-container">
            <mat-form-field appearance="outline">
              <mat-select multiple placeholder="Select" [(ngModel)]="selectedCommonStepIds[i]"
              (selectionChange)="updatePreview(i)" panelClass="data-catalog-select" (closed)="searchCommonIds = ''; selectCommonIds()">
                <div mat-select-header>
                  <div>
                    <input class="dc-dropdown-search" placeholder="Search" [(ngModel)]="searchCommonIds" (ngModelChange)="filterCommonIds(i)"/>
                  </div>
                  <div>
                    <mat-checkbox color="primary" [(ngModel)]="isCommonIdsSelectAll[i]" (change)="selectAll($event, i)">Select All</mat-checkbox>
                  </div>
                </div>
                <div class="dc-dropdown-options">
                  <mat-option *ngFor="let id of filteredCommonStepIds" [value]="id">{{id}}</mat-option>
                </div>
                <div class="dc-dropdown-add">
                  <input placeholder="Enter new item" />
                  <mat-icon>add</mat-icon>
                </div>
              </mat-select>
            </mat-form-field>
          </div>
          <div fxFlex="60px" fxLayout="row" fxLayoutGap="8px">
            <mat-icon *ngIf="i > 0" class="material-symbols-outlined global-icon-button-grey" (click)="removeRow($event,i)">remove_circle</mat-icon>
            <mat-icon *ngIf="i === rows.length - 1" class="material-symbols-outlined global-icon-button-grey" (click)="addRow()">add_circle</mat-icon>
          </div>
        </div>
      </div>
    </div>
    <div fxFlex>
      <!-- <div class="h-100 bg-surface rounded-1 p-3 y-auto">
        {{ previewText }}
      </div> -->
      <mat-form-field appearance="outline" class="h-100">
        <textarea matInput placeholder="Preview" disabled class="h-100">
          {{ previewText }}
        </textarea>
      </mat-form-field>
    </div>
  </div>
  <div fxFlex="none" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px" class="p-6 pt-0">
    <button mat-stroked-button color="primary" (click)="onClose()">Cancel</button>
    <button mat-stroked-button color="primary" (click)="onReset()">Reset</button>
    <button mat-flat-button color="primary" (click)="saveCommonIds()" [disabled]="isSaveCommonIds">Save</button>
  </div>
</div>