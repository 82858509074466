import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-sigma-common-test',
  templateUrl: './sigma-common-test.component.html',
  styleUrls: ['./sigma-common-test.component.less']
})
export class SigmaCommonTestComponent {
  rows: any[][] = [];
  sigmaCommanValues: any;
  previewText: string = '';
  commonStepIdsList: any
  isCommonIdsSelectAll: boolean[] = [];
  index:any;
  searchCommonIds: string = '';
  filteredCommonStepIds: any[] = [];
  selectedCommonStepIds: string[][] = [];
  initialCommonIds: string[][] = [];
  isSaveCommonIds: boolean = true;
  constructor(
    public dialogRef: MatDialogRef<SigmaCommonTestComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void{
    this.commonStepIdsList = this.data.list
    this.filteredCommonStepIds = this.commonStepIdsList
    this.index = this.data.row
    this.data.globalList[this.index]['common_test_ids']['selected_values'].forEach((id:any)=>{
      this.selectedCommonStepIds.push(id)
      this.rows.push(id)
    })
    this.initialCommonIds = [...this.selectedCommonStepIds]
    this.previewText = this.selectedCommonStepIds.map(row => `(${row.join(', ')})`).join('\n');
    if(this.selectedCommonStepIds.length == 0)
    {
      this.addRow()
    }
  }

  onClose() {
    this.dialogRef.close();
  }
  addRow() {
    this.rows.push([]);
    this.filteredCommonStepIds = this.commonStepIdsList
  }

  removeRow(event:any,index: number) {
    this.rows.splice(index, 1);
    this.selectedCommonStepIds.splice(index,1)
    this.updatePreview(index);
  }

  updatePreview(index:number) {
    this.isCommonIdsSelectAll = this.rows.map(row => {
      return this.commonStepIdsList.every((id:any) => row.includes(id));
    })
    let allStepsSelected = this.selectedCommonStepIds[index] ? this.filteredCommonStepIds.every((id: any) => this.selectedCommonStepIds[index].includes(id)) : false;
    this.isCommonIdsSelectAll[index] = allStepsSelected
    this.previewText = this.selectedCommonStepIds.map(row => `(${row.join(', ')})`).join('\n');
  }

  selectAll(event: any, rowIndex: number) {
    if(event.checked){
      this.selectedCommonStepIds[rowIndex] = this.filteredCommonStepIds.map((common:any)=> common)
    }
    else
    {
      this.selectedCommonStepIds[rowIndex] = []
    }
    this.updatePreview(rowIndex);
  }

  saveCommonIds(){
    this.dialogRef.close(this.selectedCommonStepIds);
  }

  filterCommonIds(index:number){
    if(this.searchCommonIds){
      let arr = this.commonStepIdsList.filter((row:any) => row.toLowerCase().includes(this.searchCommonIds.toLowerCase()))
      this.filteredCommonStepIds = this.commonStepIdsList.filter((row:any) => row.toLowerCase().includes(this.searchCommonIds.toLowerCase()))
    }
    else
    {
      this.filteredCommonStepIds = this.commonStepIdsList
    }
  }

  onReset(){
    this.selectedCommonStepIds = []
    this.previewText = ''
    this.rows = [];
    this.rows.push([])
    this.isSaveCommonIds = true;
  }

  selectCommonIds(){
    if(this.selectedCommonStepIds.length > 0){
      this.isSaveCommonIds = false;
    }
    else{
      this.isSaveCommonIds = true;
    }
  }
}
