import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './services/auth.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent {
  public IsLoggedIn: boolean = true;
  subscription: Subscription = new Subscription();

  currentComponent = 'workflow-designer';
  constructor(
    private authService: AuthService,
    private router: Router,
  ) {
    this.authService.isLoggedIn$.subscribe((isLoggedIn) => {
      this.IsLoggedIn = isLoggedIn;

      if (this.IsLoggedIn) {
        this.router.navigate(['/login']);
      } else {
        //this.router.navigate(['/']); 
      }
    });
  }
}
