<div class="pt-4 h-100 y-auto" fxLayout="column" fxLayoutGap="16px" *ngIf="!isPlotsGenerated">
    <div fxFlex="none" fxLayout="row" fxLayoutAlign="space-between center">
        <h3>Select the columns you want to generate plots.</h3>
        <div div fxLayout="row" fxLayoutGap="5px" fxLayoutAlign="end center">
            <mat-slide-toggle [(ngModel)]="isScientificNotationEnabled" fxFlex="None" style="min-width: 150px;">
                Scientific Notation
            </mat-slide-toggle>
            <mat-form-field appearance="outline">
                <mat-icon matPrefix>search</mat-icon>
                <input matInput placeholder="Search columns" (keyup)="applyFilter($event)" />
            </mat-form-field>
        </div>
    </div>
    <div fxLayoutGap="5vw" fxLayout="row" fxLayoutAlign="space-between center">
        <mat-form-field appearance="outline">
            <mat-label>Min Period</mat-label>
            <input matInput type="number" placeholder="None" step="1" [(ngModel)]="minPeriod" />
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Clip Percentiles</mat-label>
            <input matInput type="number" placeholder="99.5" step="0.1" [(ngModel)]="clipPercentiles" max="100" />
        </mat-form-field>

        <mat-form-field appearance="outline">
            <mat-label>Symmetric Bounds</mat-label>
            <mat-select [(value)]="symmetricBounds">
                <mat-option [value]="true">True</mat-option>
                <mat-option [value]="false">False</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div fxFlex class="y-auto">
        <table mat-table [dataSource]="dataSource">

            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef style="width: 60px;">
                    <mat-checkbox (change)="masterToggle()" [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
                    </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row" style="width: 60px;">
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="selection.toggle(row)"
                        [checked]="selection.isSelected(row)">
                    </mat-checkbox>
                </td>
            </ng-container>

            <ng-container matColumnDef="Column Name">
                <th mat-header-cell *matHeaderCellDef> Column Name </th>
                <td mat-cell *matCellDef="let element"> {{element['Column Name']}} </td>
            </ng-container>

            <ng-container matColumnDef="flagged">
                <th mat-header-cell *matHeaderCellDef> Flag </th>
                <td mat-cell *matCellDef="let element">
                    <mat-icon (click)="onFlagClick(element)">
                        {{ element.isFlagged ? 'flag' : 'outlined_flag' }}
                    </mat-icon>
                </td>
            </ng-container>

            <ng-container matColumnDef="min">
                <th mat-header-cell *matHeaderCellDef class="border-left"> Min </th>
                <td mat-cell *matCellDef="let element" class="border-left">
                    <span [innerHTML]="element.min | scientificNumber:isScientificNotationEnabled"></span>
                </td>
            </ng-container>

            <ng-container matColumnDef="max">
                <th mat-header-cell *matHeaderCellDef> Max </th>
                <td mat-cell *matCellDef="let element">
                    <span [innerHTML]="element.max | scientificNumber:isScientificNotationEnabled"></span>
                </td>
            </ng-container>

            <ng-container matColumnDef="mean">
                <th mat-header-cell *matHeaderCellDef> Mean </th>
                <td mat-cell *matCellDef="let element">
                    <span [innerHTML]="element.mean | scientificNumber:isScientificNotationEnabled"></span>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>

    <div fxFlex="none" class="text-end" style="overflow: hidden;">
        <button mat-flat-button color="primary" [disabled]="this.selection.selected.length < 2"
            (click)="generatePlots()">Generate Plots</button>
    </div>
</div>

<div *ngIf="isCorrelationError" class="py-4 h-100 y-auto correlation-error" fxLayout="column" fxLayoutGap="16px"
    fxLayoutAlign="center center">
    Unable to load correlation heatmap at the moment
</div>

<div *ngIf="loadingHeatmap" fxLayout="column" fxLayoutGap="16px" fxLayoutAlign="center center" class="h-100 y-auto">
    <mat-spinner diameter="48"></mat-spinner>
</div>

<!-- HEATMAP FUNCTIONALITY -->
<div class="pt-4 h-100 y-auto" fxLayout="column" fxLayoutGap="8px" *ngIf="isPlotsGenerated && !isCorrelationError">
    <div fxFlex="100%" fxLayout="row" fxLayoutGap="24px">
        <div fxFlex fxLayout="row">
            <!-- <div fxFlex>
                <div *ngIf="!isCorrelationError" id="heatmap" class="w-75 h-100"></div>
            </div> -->

            <div fxFlex="100%" *ngIf="!loadingHeatmap && !isCorrelationError">
                <object class="correlation-plot" type="text/html" [data]="heatMap"></object>
            </div>
        </div>

        <div fxFlex fxLayout="column" fxLayoutGap="8px" *ngIf="!loadingHeatmap">
            <div fxFlex="none" fxLayout="row" fxLayoutAlign="end" fxLayoutGap="8px">
                <div fxFlex>
                    <div class="fw-bold">X-axis</div>
                    <mat-form-field appearance="outline" class="correlation-visualizaion-axes">
                        <mat-select [(ngModel)]="selectedXAxisColumnName" placeholder="Select x-axis"
                            (selectionChange)="onXAxisChange($event.value)">
                            <mat-option *ngFor="let columnName of xAxisColumnNames" [value]="columnName">
                                {{ columnName }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div fxFlex>
                    <div class="fw-bold">Y-axis</div>
                    <mat-form-field appearance="outline"  class="correlation-visualizaion-axes">
                        <mat-select [(ngModel)]="selectedYAxisColumnName" placeholder="Select y-axis"
                            (selectionChange)="onYAxisChange($event.value)">
                            <mat-option *ngFor="let columnName of yAxisColumnNames" [value]="columnName">
                                {{ columnName }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div fxFlex>
                    <div class="fw-bold">Color by</div>
                    <mat-form-field appearance="outline">
                        <mat-select [(ngModel)]="selectedColourBy" placeholder="Select color by"
                            (selectionChange)="onColourByChange($event.value)">
                            <mat-option *ngFor="let option of availableColourByOptions" [value]="option">
                                {{ option }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>

            <div fxFlex class="p-relative">
                <div *ngIf="showLoader">
                    <div fxLayout="column" fxLayoutAlign="center center">
                        <mat-spinner diameter="48"></mat-spinner>
                    </div>
                </div>
                <div class="graph-container" *ngIf="!showLoader">
                    <object *ngIf="interactive && plotData?.get('graph1')" type="text/html"
                        [data]="plotData.get('graph1')" class="interactive-graph"></object>
                    <img *ngIf="!interactive && plotData?.get('graph1')" [src]="plotData.get('graph1')"
                        class="image-graph">
                </div>
            </div>

            <!-- <div fxFlex="180px" fxFlex.lt-xl="150px" fxlayout="row" fxLayoutGap="24px" *ngIf="plotData.get('graph2') || plotData.get('graph3')">
                <div fxFlex>
                    <div style="width: 100%; height: 100%" *ngIf="!showLoader">
                        <object *ngIf="interactive && plotData.get('graph2')" style="width: 100%; height: 100%" type="text/html"
                                [data]="plotData.get('graph2')"></object>
                        <img *ngIf="!interactive && plotData.get('graph2')" [src]="plotData.get('graph2')" style="width: 100%; height: 100%">
                    </div>
                </div>
                <div fxFlex>
                    <div style="width: 100%; height: 100%" *ngIf="!showLoader">
                        <object *ngIf="interactive && plotData.get('graph3')" style="width: 100%; height: 100%" type="text/html"
                                [data]="plotData.get('graph3')"></object>
                        <img *ngIf="!interactive && plotData.get('graph3')" [src]="plotData.get('graph3')" style="width: 100%; height: 100%">
                    </div>
                </div>
            </div> -->
        </div>
    </div>

    <div fxFlex="none" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px" style="overflow: hidden;">
        <div>
            <mat-slide-toggle [(ngModel)]="interactive" color="primary">Interactive Plot</mat-slide-toggle>
        </div>
        <div>
            <button mat-flat-button color="primary" (click)="backToColumnsScreen()">Back to Columns</button>
        </div>
    </div>
</div>