<div class="h-100 p-8" fxLayout="column" fxLayoutGap="8px">
  <div fxFlex="none">
    <h1 class="global-main-title">Custom Widgets
      <mat-icon class="material-symbols-outlined global-info-icon"
        matTooltip="This page is a repository for users to create and manage their custom widgets, which users can create to perform data transformations and processes that are not supported by the native widgets on MIND.">
        info
      </mat-icon>
    </h1>
  </div>
  <div fxFlex="none" fxLayout="row" fxLayoutAlign="space-between center" class="fixed-header">
    <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign=" center" fxHide.lt-sm>
      <div fxFlex="220px" fxFlex.lt-md="150px">
        <mat-form-field appearance="outline">
          <mat-label>Search</mat-label>
          <mat-icon matPrefix>search</mat-icon>
          <input matInput placeholder="Search" [(ngModel)]="searchText" (input)="onSearchTextChange($event)" />
        </mat-form-field>
      </div>
      <div fxFlex="160px" fxHide.lt-lg>
        <mat-form-field appearance="outline">
          <mat-select id="userRole" name="userRole" placeholder="Select Owner" >
            <mat-option *ngIf="custom_owners?.length > 0" [value]="allOwners" (click)="allOwner()">All Owners</mat-option>
            <mat-option *ngFor="let project of custom_owners" [value]="project.owner_id" (click)="projectOwner(project)">{{ project.owner_name }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div fxFlex="none">
        <div class="global-icon-button" (click)="onToggleSort()">
          <mat-icon>swap_vert</mat-icon>
        </div>
      </div>
      <div fxFlex="none">
        <div class="global-icon-button" (click)="onRefreshView()">
          <mat-icon>cached</mat-icon>
        </div>
      </div>
    </div>
    <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="end center">
      <div>
        <button mat-flat-button color="primary" (click)="createNewCustomWidget()">
          <mat-icon class="material-symbols-outlined">add</mat-icon>
          Create <span fxHide.lt-md>Custom Widget</span>
        </button>
      </div>
    </div>
  </div>
  <div fxFlex class="y-auto">
    <div class="centered-info-message" *ngIf="customWidgets.length === 0 && isCustomPythonRecipeLoaded"> No Custom
      Widgets are present for this project </div>
    <ng-container *ngIf="customWidgets.length != 0 && isCustomPythonRecipeLoaded">
      <table mat-table [dataSource]="customWidgets" matSort (matSortChange)="sortData($event)">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
          <td mat-cell *matCellDef="let element">{{ element.name ? element.name : "Unknown" }}</td>
        </ng-container>

        <ng-container matColumnDef="owner">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Owner</th>
          <td mat-cell *matCellDef="let element">{{ element.created_by_name ? element.created_by_name : "Unknown" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
          <td mat-cell *matCellDef="let element">{{ element.description ? element.description : "Unknown" }}</td>
        </ng-container>

        <ng-container matColumnDef="created_at">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Date Created&nbsp;<span class="text-sm text-grey">(Local Time)</span>
          </th>
          <td mat-cell *matCellDef="let element">{{ element.created_at | date: 'dd-MMM-yyyy h:mm a' }}</td>
        </ng-container>

        <ng-container matColumnDef="lastModified">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            Last Modified&nbsp;<span class="text-sm text-grey">(Local Time)</span>
          </th>
          <td mat-cell *matCellDef="let element">{{ element.last_modified_at | date: 'dd-MMM-yyyy h:mm a' }}</td>
        </ng-container>
        
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
          <td mat-cell *matCellDef="let element" [ngClass]="getCssClassForWidgetStatus(element)">{{ element.recipe_status }}</td>
        </ng-container>

        <ng-container matColumnDef="more_vert">
          <th mat-header-cell *matHeaderCellDef>&nbsp;</th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button (click)="menuItem = element; $event.stopPropagation()"
              [matMenuTriggerFor]="actionsMenu" (click)="deleteConfirmation = false">
              <mat-icon>more_vert</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="menuItem = row"
          (click)="onCustomWidgetRowSingleClick(row)" (click)="onRowClicked(row)"
          [class.selected]="isCustomWidgetRowSelected(row)" class="custom-mat-row"></tr>
      </table>
    </ng-container>
  </div>
</div>

<mat-menu #actionsMenu="matMenu">
  <ng-container *ngIf="!deleteConfirmation">
    <button mat-menu-item (click)="openCustomWidget(menuItem, 'VIEW')">View</button>
    <button mat-menu-item (click)="openCustomWidget(menuItem, 'EDIT')">Edit</button>
    <button mat-menu-item (click)="openCloneWidgetForm(menuItem)">Clone</button>
    <button
      mat-menu-item
      (click)="openValidationDialog(menuItem._id,menuItem.name)"
    >
      Delete
    </button>
  </ng-container>
</mat-menu>

<ng-template #popupTemplate>
  <div class="custom-dialog-container" *ngIf="workFlowsInWhichWidgetIsUsed.size">
    <h2 mat-dialog-title>By deleting ({{currentWidgetRecipeName}}) widget these following workflow's will get effected, do you want to delete anyway?</h2>
    <div class="workflow-list">
      <div class="workflow-item" *ngFor="let workflow of workFlowsInWhichWidgetIsUsed">
        {{workflow}}
      </div>
    </div>
    <mat-dialog-actions class="dialog-actions">
      <button class="custom-dialog-container-button" mat-button (click)="proceedToDelete(true)">Yes</button>
      <button class="custom-dialog-container-button" mat-button (click)="proceedToDelete(false)">No</button>
    </mat-dialog-actions>
  </div>
  <!-- <div *ngIf="isValidationInProgress || isPublishingInProgress" class="custom-dialog-container">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    <span class="loading-message">{{validationMessage}}</span>
  </div>
  <div *ngIf="isValidationFailed">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    <span class="loading-message">{{validationMessage}}</span>
  </div> -->
</ng-template>

<ng-template #cloneForm>
  <form style="height: 90px; width: 600px; display:flex; gap:10px; padding:20px" [formGroup]="singleInputForm" (ngSubmit)="procedToCloneWidget()">
    <mat-form-field>
      <mat-label>Enter the name for custom widget clone</mat-label>
      <input matInput formControlName="inputField" placeholder="Enter the name for custom widget clone" [(ngModel)]="customWidgetClonedName" >
      <mat-error *ngIf="true">
        Custom Widget with this name is already present
      </mat-error>
    </mat-form-field>
    <button mat-flat-button color="primary" type="submit">Submit</button>
  </form>
</ng-template>
<!-- singleInputForm?.get('inputField')?.value == 'test' --> 
