<div fxLayout="column" class="dsg-container">
  <div fxFlex="none" *ngIf="showMainHeader"><app-header></app-header></div>
  <div
    fxFlex="none"
    fxLayout="row"
    fxLayoutAlign="space-between center"
    class="header"
  >
    <div fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="4px">
      <div>
        <button
          mat-icon-button
          class="global-back-button"
          (click)="navigate('tools')"
        >
          <mat-icon>arrow_back</mat-icon>
        </button>
      </div>
      <div class="title">Automatic Datasheet config editor</div>
    </div>
    <div fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="4px">
      <div class="border-left">
        <button
          mat-icon-button
          class="global-icon-button"
          (click)="toggleMainHeader()"
        >
          <mat-icon class="material-symbols-outlined">
            {{ toggleHeaderButton }}
          </mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div fxFlex fxLayout="row" class="y-auto">
    <div fxLayout="column" fxLayoutGap="12px" class="left-panel">
      <div>
        <button
          mat-flat-button
          color="primary"
          class="w-100"
          (click)="navigate('converter')"
        >
          Converter
        </button>
        <!-- <mat-spinner class="floating-spinner" diameter="20"></mat-spinner> -->
      </div>
      <div>
        <button
          mat-flat-button
          color="primary"
          class="w-100"
          (click)="navigate('data-sheets')"
          [disabled]="dataSheetsArray.length == 0"
        >
          Data Sheets
        </button>
        <!-- <mat-spinner class="floating-spinner" diameter="20"></mat-spinner> -->
      </div>
      <div>
        <mat-accordion class="global-accordion">
          <mat-expansion-panel [expanded]="true">
            <mat-expansion-panel-header>
              Data Source
            </mat-expansion-panel-header>
            <div class="pb-2 p-relative">
              <mat-form-field appearance="outline">
                <input
                  type="text"
                  matInput
                  placeholder="Choose folder"
                  [(ngModel)]="selectedFolder"
                  readonly="true"
                  #fileInputText
                  (click)="fileInput.click()"
                />
              </mat-form-field>
              <mat-spinner
                class="floating-spinner"
                diameter="20"
                *ngIf="uploadLoader"
              ></mat-spinner>
            </div>
            <div>
              <input
                type="file"
                #fileInput
                style="display: none"
                (change)="onDirectorySelected($event)"
                directory
                multiple
                webkitdirectory
              />
            </div>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="8px">
              <div fxFlex>
                <button
                  mat-flat-button
                  class="global-button-sm w-100"
                  color="primary"
                  (click)="fileInput.click()"
                >
                  Browse
                </button>
              </div>
              <div fxFlex>
                <button
                  mat-flat-button
                  class="global-button-sm w-100"
                  color="primary"
                  (click)="uploadDirectory()"
                  [disabled]="selectedFolder == ''"
                >
                  Upload
                </button>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>

      <div fxLayout="column" fxLayoutGap="16px">
        <div fxLayout="column" fxLayoutGap="16px" class="border-bottom pb-6">
          <div>
            <div class="fw-semi-bold pb-1">Data Sheet Number</div>
            <mat-form-field appearance="outline">
              <mat-select
                placeholder="Select value"
                name="datasheetId"
                [(ngModel)]="datasheetId"
                (selectionChange)="onDataChange(false, 'sheetNumber')"
                [disabled]="dataSheetsArray.length === 0"
              >
                <mat-option
                  *ngFor="let sheet of dataSheetsArray"
                  [value]="sheet"
                >
                  {{ sheet }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div *ngIf="datasheetId">
            <div class="fw-semi-bold pb-1">Nominal Age</div>
            <mat-form-field appearance="outline">
              <mat-select
                placeholder="Select value"
                name="nominalAge"
                [(ngModel)]="nominalAge"
                (selectionChange)="onDataChange(false, 'nominalAge')"
              >
                <mat-option *ngFor="let age of nominalAgeArray" [value]="age">
                  {{ age }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div fxLayout="column" fxLayoutGap="8px" *ngIf="showButtons">
            <button
              *ngIf="streamLiteLoadData"
              mat-stroked-button
              color="primary"
              class="w-100"
              (click)="resetConfig()"
            >
              Reset Configurations
            </button>
            <div class="p-relative">
              <button
                *ngIf="streamLiteLoadData"
                mat-stroked-button
                color="primary"
                class="w-100"
                (click)="generateDataSheet('initial')"
              >
                Gen Initial Datasheet
              </button>
            </div>
            <div class="p-relative">
              <button
                *ngIf="streamLiteLoadData"
                mat-stroked-button
                color="primary"
                class="w-100"
                (click)="generateDataSheet('new')"
              >
                Gen New Datasheet
              </button>
            </div>
            <div
              fxLayout="row"
              fxLayoutGap="4px"
              fxLayoutAlign="center center"
              *ngIf="dataSheetSpinner"
            >
              <div class="text-primary">Generating Datasheet</div>
              <div>
                <mat-spinner diameter="20"></mat-spinner>
              </div>
            </div>

            <button
              *ngIf="dataSheetGenerated"
              mat-stroked-button
              color="primary"
              class="w-100"
              (click)="previewDataSheet()"
            >
              Preview Datasheet
            </button>

            <button
              *ngIf="dataSheetGenerated"
              mat-stroked-button
              color="primary"
              class="w-100"
              (click)="downloadDataSheetResults()"
            >
              Download Results
            </button>
          </div>

          <!-- <div fxLayout="column" fxLayoutGap="8px">
            <app-file-tree-node
              [path]="basePath"
              [level]="0"
            ></app-file-tree-node>
          </div> -->
        </div>
      </div>
    </div>
    <div fxFlex fxLayout="column" fxLayoutGap="24px" class="p-6 h-100 y-auto">
      <div
        *ngIf="loader == true"
        fxLayout="row"
        fxLayoutAlign="center center"
        class="h-100"
      >
        <mat-spinner diameter="48"></mat-spinner>
      </div>
      <mat-accordion
        *ngIf="loader == false && streamLiteLoadData"
        class="global-accordion"
        multi
      >
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header class="">
            <mat-panel-title>Datasheet Configurations</mat-panel-title>
          </mat-expansion-panel-header>
          <div fxLayout="column" fxLayoutGap="16px">
            <div fxFlex="none" fxLayout="row" fxLayoutGap="16px">
              <div fxFlex>
                <div class="fw-semi-bold pb-1">
                  Product Commercial name
                  <span class="required-asterisk">*</span>
                </div>
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    placeholder="Enter name"
                    name="productName"
                    [(ngModel)]="productName"
                    (ngModelChange)="onChange()"
                    (focus)="onFocus()"
                    (blur)="onBlur()"
                    required
                  />
                </mat-form-field>
              </div>

              <div fxFlex>
                <div class="fw-semi-bold pb-1">
                  Gauge <span class="required-asterisk">*</span>
                </div>

                <mat-form-field appearance="outline">
                  <input
                    matInput
                    type="number"
                    name="gauge"
                    [(ngModel)]="gauge"
                    (ngModelChange)="onChange()"
                    (focus)="onFocus()"
                    (blur)="onBlur()"
                    placeholder="Enter value"
                    min="1"
                    step="0.01"
                    required
                  />
                </mat-form-field>
              </div>

              <div fxFlex>
                <div class="fw-semi-bold pb-1">
                  Material Source <span class="required-asterisk">*</span>
                </div>
                <mat-form-field appearance="outline">
                  
                  <mat-select
                    name="m_source"
                    [(ngModel)]="m_source"
                    (ngModelChange)="onChange()"
                    (focus)="onFocus()"
                    (blur)="onBlur()"
                    placeholder="Select material source"
                    required
                  >
                    <mat-option value="Europe">Europe</mat-option>
                    <mat-option value="North America">North America</mat-option>
                    <mat-option value="Asia">Asia</mat-option>
                  </mat-select>
                </mat-form-field>
                
              </div>
            </div>
            <div fxFlex="none" fxLayout="row" fxLayoutGap="16px">
              <div fxFlex>
                <div class="fw-semi-bold pb-1">
                  Branch <span class="required-asterisk">*</span>
                </div>
                <mat-form-field appearance="outline">
                  <mat-select
                    placeholder="Select branch"
                    name="branchName"
                    [(ngModel)]="branchName"
                    (ngModelChange)="saveMetadata()"
                    required
                  >
                    <mat-option value="production">Production</mat-option>
                    <mat-option value="testing">Testing</mat-option>
                    <mat-option value="development">Development</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div fxFlex>
                <div class="fw-semi-bold pb-1">
                  Latest revalidation <span class="required-asterisk">*</span>
                </div>
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    [matDatepicker]="picker1"
                    placeholder="MM/DD/YYYY"
                    [(ngModel)]="latestRevalidationDate"
                    (ngModelChange)="saveMetadata()"
                    name="latestRevalidationDate"
                    required
                  />
                  <mat-datepicker-toggle
                    matIconSuffix
                    [for]="picker1"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
              </div>
              <div fxFlex>
                <div class="fw-semi-bold pb-1">
                  Valid until <span class="required-asterisk">*</span>
                </div>
                <mat-form-field appearance="outline">
                  <input
                    matInput
                    [matDatepicker]="picker2"
                    placeholder="MM/DD/YYYY"
                    [(ngModel)]="validUntilDate"
                    (ngModelChange)="saveMetadata()"
                    name="validUntilDate"
                    required
                  />
                  <mat-datepicker-toggle
                    matIconSuffix
                    [for]="picker2"
                  ></mat-datepicker-toggle>
                  <mat-datepicker #picker2></mat-datepicker>
                </mat-form-field>
              </div>
              <div fxFlex>
                <div class="fw-semi-bold pb-1">
                  Relevant customer specs
                  <span class="required-asterisk">*</span>
                </div>
                <mat-form-field appearance="outline">
                  <mat-select
                    placeholder="Choose an option"
                    name="specName"
                    [(ngModel)]="specName"
                    (ngModelChange)="saveMetadata()"
                    required
                  >
                    <mat-option value="specA">Spec A</mat-option>
                    <mat-option value="spec2">Spec 2</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title class=""> Tensile Related </mat-panel-title>
          </mat-expansion-panel-header>
          <div fxLayout="column" fxLayoutGap="16px">
            <div fxFlex="none" class="p-relative">
              <div class="fw-semi-bold pb-1">Included Tensile Samples</div>
              <mat-form-field>
                <mat-chip-grid #tensileSample aria-label="Sample selection">
                  <mat-chip-row
                    *ngFor="let option of tensileSelectedOptions"
                    (removed)="remove(option, 'tensile')"
                  >
                    {{ option }}
                    <button
                      matChipRemove
                      [attr.aria-label]="'remove ' + option"
                    >
                      <mat-icon class="text-red">cancel</mat-icon>
                    </button>
                  </mat-chip-row>
                </mat-chip-grid>
                <input
                  matInput
                  [matChipInputFor]="tensileSample"
                  (matChipInputTokenEnd)="add($event, 'tensile')"
                  (click)="focusOptions('tensile')"
                />
              </mat-form-field>
              <mat-icon
                *ngIf="tensileSelectedOptions.length > 0"
                class="close-all-btn"
                (click)="removeAll('tensile')"
                matTooltip="Remove all"
                >cancel</mat-icon
              >
            </div>

            <div
              fxFlex="none"
              class="rounded-2 bg-surface p-4"
              *ngIf="tensilePanel"
            >
              <div fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="16px">
                <div fxFlex class="fw-semi-bold pb-1">
                  Excluded Tensile Samples
                </div>
                <div
                  *ngIf="tensileExcludedData.length > 0"
                  fxFlex
                  class="fw-semi-bold pb-1"
                >
                  Reason for excluding
                </div>
                <div fxFlex="20px">&nbsp;</div>
              </div>

              <div *ngIf="tensileExcludedData.length === 0">
                No file available
              </div>

              <div
                fxLayout="row"
                fxLayoutAlign="center"
                fxLayoutGap="16px"
                class="pb-1"
                *ngFor="let excluded of tensileExcludedData; let i = index"
              >
                <div fxFlex>{{ excluded.file_name }}</div>
                <div fxFlex>
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      placeholder="Reason"
                      [(ngModel)]="excluded.reason"
                      (ngModelChange)="
                        onInputChange(excluded.file_name, 'tensile')
                      "
                      name="tensileReasons{{ i }}"
                    />
                    <button
                      mat-icon-button
                      matSuffix
                      color="primary"
                      (click)="
                        saveExcludedFile(
                          excluded.file_name,
                          excluded.reason,
                          i,
                          'tensile'
                        )
                      "
                      [disabled]="
                        !tensileRecentlyChangedFiles.has(excluded.file_name)
                      "
                    >
                      <mat-icon>check</mat-icon>
                    </button>
                  </mat-form-field>
                </div>
                <div fxFlex="20px">
                  <mat-icon
                    class="remove-btn"
                    (click)="
                      selected(excluded.file_name, 'tensile');
                      removeExcludedFile(excluded.file_name, 'tensile')
                    "
                    >cancel</mat-icon
                  >
                </div>
              </div>
            </div>
            <ng-container
              *ngIf="
                dataSheetResults && dataSheetResults.tensile_plot_data_path
              "
            >
              <div
                class="x-auto rounded-2 bg-surface p-4"
                fxLayout="column"
                fxLayoutGap="16px"
              >
                <div id="plotly-div-1"></div>
              </div>
            </ng-container>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title class=""> Bulge Related </mat-panel-title>
          </mat-expansion-panel-header>
          <div fxLayout="column" fxLayoutGap="16px">
            <div fxFlex="none" class="p-relative">
              <div class="fw-semi-bold pb-1">Included Bulge Samples</div>
              <mat-form-field>
                <mat-chip-grid #bulgeSample aria-label="Sample selection">
                  <mat-chip-row
                    *ngFor="let option of bulgeSelectedOptions"
                    (removed)="remove(option, 'bulge')"
                  >
                    {{ option }}
                    <button
                      matChipRemove
                      [attr.aria-label]="'remove ' + option"
                    >
                      <mat-icon class="text-red">cancel</mat-icon>
                    </button>
                  </mat-chip-row>
                </mat-chip-grid>
                <input
                  matInput
                  [matChipInputFor]="bulgeSample"
                  (matChipInputTokenEnd)="add($event, 'bulge')"
                  (click)="focusOptions('bulge')"
                />
              </mat-form-field>
              <mat-icon
                *ngIf="bulgeSelectedOptions.length > 0"
                class="close-all-btn"
                (click)="removeAll('bulge')"
                matTooltip="Remove all"
                >cancel</mat-icon
              >
            </div>

            <div
              fxFlex="none"
              class="rounded-2 bg-surface p-4"
              *ngIf="bulgePanel"
            >
              <div fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="16px">
                <div fxFlex class="fw-semi-bold pb-1">
                  Excluded Bulge Samples
                </div>
                <div
                  *ngIf="bulgeExcludedData.length > 0"
                  fxFlex
                  class="fw-semi-bold pb-1"
                >
                  Reason for excluding
                </div>
                <div fxFlex="20px">&nbsp;</div>
              </div>

              <div *ngIf="bulgeExcludedData.length === 0">
                No file available
              </div>

              <div
                *ngFor="let excluded of bulgeExcludedData; let i = index"
                fxLayout="row"
                fxLayoutAlign="center"
                fxLayoutGap="16px"
                class="pb-1"
              >
                <div fxFlex>{{ excluded.file_name }}</div>
                <div fxFlex>
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      placeholder="Reason"
                      [(ngModel)]="excluded.reason"
                      (ngModelChange)="
                        onInputChange(excluded.file_name, 'bulge')
                      "
                      name="bulgeReasons{{ i }}"
                    />
                    <button
                      mat-icon-button
                      matSuffix
                      color="primary"
                      (click)="
                        saveExcludedFile(
                          excluded.file_name,
                          excluded.reason,
                          i,
                          'bulge'
                        )
                      "
                      [disabled]="
                        !bulgeRecentlyChangedFiles.has(excluded.file_name)
                      "
                    >
                      <mat-icon>check</mat-icon>
                    </button>
                  </mat-form-field>
                </div>
                <div fxFlex="20px">
                  <mat-icon
                    class="remove-btn"
                    (click)="
                      selected(excluded.file_name, 'bulge');
                      removeExcludedFile(excluded.file_name, 'bulge')
                    "
                    >cancel</mat-icon
                  >
                </div>
              </div>
            </div>
            <ng-container
              *ngIf="dataSheetResults && dataSheetResults.bulge_plot_data_path"
            >
              <div
                class="x-auto rounded-2 bg-surface p-4"
                fxLayout="column"
                fxLayoutGap="16px"
              >
                <div id="plotly-div-2"></div>
              </div>
            </ng-container>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title class=""
              >Corrected Tensile Related</mat-panel-title
            >
          </mat-expansion-panel-header>
          <div
            *ngIf="
              dataSheetResults && dataSheetResults.corrected_tensile_results
            "
            fxLayout="column"
            fxLayoutGap="16px"
          >
            <div fxFlex="none">
              <div class="fw-semi-bold pb-1">
                Select Tensile Sample to Correct
              </div>
              <mat-form-field appearance="outline">
                <mat-select
                  placeholder="Choose an option"
                  [(ngModel)]="selectedTensileSample"
                  (selectionChange)="fileChangeOnCorrectedResults()"
                >
                  <mat-option
                    *ngFor="let file of dataSheetResults.tensile_files"
                    [value]="file"
                  >
                    {{ file }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div
              fxFlex="none"
              fxLayout="column"
              fxLayoutGap="16px"
              class="rounded-2 bg-surface p-4"
            >
              <div class="fw-semi-bold text-md">Elastic modulus fitting</div>
              <div fxLayout="row" fxLayoutGap="16px">
                <div fxFlex>
                  <div class="fw-semi-bold pb-1">
                    Stress fit lower bound [MPa]
                  </div>
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      type="number"
                      placeholder="Enter value"
                      min="1"
                      step="0.01"
                      [ngModel]="getStressFitLowerBound()"
                      (ngModelChange)="setStressFitLowerBound($event)"
                      name="s_min"
                    />
                  </mat-form-field>
                </div>
                <div fxFlex>
                  <div class="fw-semi-bold pb-1">
                    Stress fit upper bound [MPa]
                  </div>
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      type="number"
                      placeholder="Enter value"
                      min="1"
                      step="0.01"
                      [ngModel]="getStressFitUpperBound()"
                      (ngModelChange)="setStressFitUpperBound($event)"
                      name="s_max"
                    />
                  </mat-form-field>
                </div>
              </div>

              <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign=" center">
                <div fxFlex>
                  <button
                    mat-stroked-button
                    color="primary"
                    (click)="computeElasticModule()"
                  >
                    Compute Elastic Modulus
                  </button>
                </div>
                <div fxFlex>
                  <mat-checkbox color="primary" [(ngModel)]="recomputeRp02"
                    >Recompute Rp02</mat-checkbox
                  >
                </div>
              </div>
            </div>
            <div
              *ngIf="
                dataSheetResults && dataSheetResults.corrected_tensile_results
              "
              class="x-auto rounded-2 bg-surface p-4"
            >
              <div class="custom-table-wrapper">
                <div class="float">
                  <table>
                    <thead>
                      <tr>
                        <th
                          *ngFor="
                            let signature of getCorrectedSignatures();
                            trackBy: trackBySignature
                          "
                        >
                          {{ signature }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        *ngFor="
                          let result of dataSheetResults
                            .corrected_tensile_results.df_results;
                          let rowIndex = index
                        "
                      >
                        <td
                          *ngFor="
                            let signature of getCorrectedSignatures();
                            let colIndex = index
                          "
                          (dblclick)="
                            switchEditMode(rowIndex, colIndex, true, signature)
                          "
                          (focusout)="
                            switchEditMode(rowIndex, colIndex, false, signature)
                          "
                        >
                          <span
                            *ngIf="!isEditingCR[rowIndex + '-' + colIndex]"
                            >{{
                              getRoundedValue(result[signature], signature)
                            }}</span
                          >
                          <input
                            *ngIf="isEditingCR[rowIndex + '-' + colIndex]"
                            [ngModel]="
                              getValueCorrectedTensile(rowIndex, signature)
                            "
                            (ngModelChange)="
                              setValueCorrectedTensile(
                                rowIndex,
                                signature,
                                $event
                              )
                            "
                            [attr.data-param]="rowIndex + '-' + colIndex"
                            [attr.data-signature]="signature"
                            (blur)="switchEditMode(rowIndex, colIndex, false)"
                            class="editable-input"
                            type="number"
                            autofocus
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div
              class="x-auto rounded-2 bg-surface p-4"
              fxFlex="none"
              fxLayout="column"
              fxLayoutGap="16px"
            >
              <div id="plotly-div-3"></div>
            </div>
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header class="">
            <mat-panel-title>Adjust model parameters</mat-panel-title>
          </mat-expansion-panel-header>
          <div
            *ngIf="dataSheetResults && dataSheetResults.hss_weights_object"
            fxLayout="column"
            fxLayoutGap="16px"
          >
            <div fxFlex="none">
              <div class="fw-semi-bold pb-1">Model to adjust</div>
              <mat-form-field appearance="outline">
                <mat-select
                  placeholder="Choose an option"
                  [(ngModel)]="modelAdjust"
                >
                  <mat-option value="Hocket Sherby Swift"
                    >Hocket Sherby Swift</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
            <div
              fxFlex="none"
              fxLayout="column"
              fxLayoutGap="16px"
              class="rounded-2 bg-surface p-4"
            >
              <div fxLayout="row" fxLayoutGap="16px">
                <div fxFlex>
                  <div class="fw-semi-bold pb-1">Tensile Weight</div>
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      type="number"
                      min="1"
                      step="0.01"
                      [ngModel]="tensileWeight"
                      (ngModelChange)="tensileWeight = $event"
                    />
                  </mat-form-field>
                </div>
                <div fxFlex>
                  <div class="fw-semi-bold pb-1">Bulge Weight</div>
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      type="number"
                      min="1"
                      step="0.01"
                      [ngModel]="bulgeWeight"
                      (ngModelChange)="bulgeWeight = $event"
                    />
                  </mat-form-field>
                </div>
                <div fxFlex>
                  <div class="fw-semi-bold pb-1">Considere Weight</div>
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      type="number"
                      min="1"
                      step="0.01"
                      [ngModel]="considereWeight"
                      (ngModelChange)="considereWeight = $event"
                    />
                  </mat-form-field>
                </div>
              </div>
              <!-- <div fxFlex>
                <mat-checkbox
                  color="primary"
                  [ngModel]="scaleTensileBulge"
                  (ngModelChange)="scaleTensileBulge = $event"
                  >Scale Tensile & Bulge</mat-checkbox
                >
              </div> -->
              <div fxFlex>
                <button
                  (click)="reset_weights()"
                  mat-stroked-button
                  color="primary"
                >
                  Reset Weight to default
                </button>
              </div>
            </div>

            <div
              *ngIf="dataSheetResults && dataSheetResults.hss_complete_params"
              class="x-auto rounded-2 bg-surface p-4"
            >
              <div class="custom-table-wrapper">
                <div class="float">
                  <table>
                    <thead>
                      <tr>
                        <th>Index</th>
                        <th *ngFor="let signature of getSignatures()">
                          {{ signature }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let param of parametersOnlyParam_value()">
                        <td>{{ param }}</td>
                        <td *ngFor="let signature of getSignatures()">
                          <div
                            *ngIf="!isEditing(param, signature); else editInput"
                            (dblclick)="enableEdit(param, signature)"
                            class="editable-cell"
                          >
                            {{ getCellValue(signature, param) }}
                          </div>
                          <ng-template #editInput>
                            {{ getCellValue(signature, param) }}
                          </ng-template>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div
              *ngIf="dataSheetResults && dataSheetResults.hss_complete_params"
              class="x-auto rounded-2 bg-surface p-4"
            >
              <div class="custom-table-wrapper" style="height: 200px">
                <div class="float">
                  <table>
                    <thead>
                      <tr>
                        <th>Index</th>
                        <th *ngFor="let signature of getSignatures()">
                          {{ signature }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let param of parametersWithOutParam_value()">
                        <td>{{ param }}</td>
                        <td *ngFor="let signature of getSignatures()">
                          <div
                            *ngIf="!isEditing(param, signature); else editInput"
                            (dblclick)="enableEdit(param, signature)"
                            class="editable-cell"
                          >
                            {{ getCellValue(signature, param) }}
                          </div>
                          <ng-template #editInput>
                            <input
                              #inputField
                              [ngModel]="getCellValue(signature, param)"
                              (ngModelChange)="
                                setCellValue(signature, param, $event)
                              "
                              (blur)="disableEdit(param, signature)"
                              (keyup.enter)="disableEdit(param, signature)"
                              [attr.data-param]="param"
                              [attr.data-signature]="signature"
                              autofocus
                            />
                          </ng-template>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>

            <div
              class="x-auto rounded-2 bg-surface p-4"
              fxFlex="none"
              fxLayout="column"
              fxLayoutGap="16px"
            >
              <div fxLayout="row" fxLayoutGap="16px">
                <div fxFlex>
                  <div class="fw-semi-bold pb-1">Window tensile gradient</div>
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      type="number"
                      matInput
                      type="number"
                      min="1"
                      step="0.01"
                      [(ngModel)]="tensile_grad"
                      placeholder="Enter value"
                      value="1.00"
                      min="1"
                      step="0.01"
                    />
                  </mat-form-field>
                </div>
                <div fxFlex>
                  <div class="fw-semi-bold pb-1">Window bulge gradient</div>
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      [(ngModel)]="bulge_grad"
                      type="number"
                      placeholder="Enter value"
                      value="60.00"
                      min="1"
                      step="0.01"
                    />
                  </mat-form-field>
                </div>
                <div fxFlex>
                  <div class="fw-semi-bold pb-1">Window tensile n-value</div>
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      [(ngModel)]="tensile_n_grad"
                      type="number"
                      placeholder="Enter value"
                      value="60.00"
                      min="1"
                      step="0.01"
                    />
                  </mat-form-field>
                </div>
                <div fxFlex>
                  <div class="fw-semi-bold pb-1">Window bulge n-value</div>
                  <mat-form-field appearance="outline">
                    <input
                      matInput
                      [(ngModel)]="bulge_n_grad"
                      type="number"
                      placeholder="Enter value"
                      value="60.00"
                      min="1"
                      step="0.01"
                    />
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div
              fxFlex="none"
              fxLayout="row"
              fxLayoutGap="8px"
              fxLayoutAlign=" center"
            >
              <button
                mat-stroked-button
                color="primary"
                (click)="computeLocalFit(true)"
                [disabled]="modelGraphLoader"
              >
                Compute new local fit
              </button>
              <div>
                <mat-spinner
                  *ngIf="modelGraphLoader"
                  diameter="24"
                ></mat-spinner>
              </div>
            </div>
            <mat-tab-group
              mat-stretch-tabs="false"
              mat-align-tabs="start"
              *ngIf="showTabs"
              [selectedIndex]="activeTabIndexStressStrain"
              (selectedTabChange)="onTabChangeStressStrain($event)"
            >
              <mat-tab label="Initial VS New Hardening Fit (Stress/Strain)">
                <ng-template matTabContent>
                  <div id="plotDiv1"></div>
                </ng-template>
              </mat-tab>
            </mat-tab-group>
            <mat-tab-group
              mat-stretch-tabs="false"
              mat-align-tabs="start"
              *ngIf="showTabs"
              [selectedIndex]="activeTabIndexNValues"
              (selectedTabChange)="onTabChangeNValues($event)"
            >
              <mat-tab label="Initial VS New N-Values">
                <ng-template matTabContent>
                  <div id="plotDiv3"></div>
                </ng-template>
              </mat-tab>
            </mat-tab-group>
            <div
              *ngIf="modelResults && modelResults.df_model_params"
              class="x-auto rounded-2 bg-surface p-4"
              fxFlex="none"
              fxLayout="column"
              fxLayoutGap="16px"
            >
              <div class="fw-semi-bold text-md">Comparison of models</div>
              <div class="fw-semi-bold pb-1">Model parameters</div>
              <div class="custom-table-wrapper" style="height: 156px">
                <div class="float">
                  <table>
                    <thead>
                      <tr>
                        <th></th>
                        <ng-container
                          *ngFor="
                            let key of getKeys(
                              modelComparisonData['current fit']
                            )
                          "
                        >
                          <th>{{ key }}</th>
                        </ng-container>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of getKeys(modelComparisonData)">
                        <td class="model_fitting">
                          {{
                            item === "current fit"
                              ? "Initial Fit"
                              : item === "new local fit"
                                ? "New Local Fit"
                                : item
                          }}
                        </td>
                        <ng-container
                          *ngFor="let key of getKeys(modelComparisonData[item])"
                        >
                          <td>
                            {{ getFitValue(item, key) }}
                          </td>
                        </ng-container>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="fw-semi-bold pb-1">Model errors</div>
              <div class="custom-table-wrapper" style="height: 156px">
                <div class="float">
                  <table>
                    <thead>
                      <tr>
                        <th></th>
                        <ng-container>
                          <th>Error</th>
                        </ng-container>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td class="model_fitting">Initial model error</td>
                        <td>
                          {{ getErrorValue("m_err_current") | number:'1.0-9' }}
                        </td>
                      </tr>
                      <tr *ngIf="this.modelResults.m_err_new">
                        <td class="model_fitting">New model error</td>
                        <td>
                          {{ getErrorValue("m_err_new") | number:'1.0-9' }}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
</div>
