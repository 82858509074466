<div fxLayout="column" fxLayoutGap="16px"> 
    
      
    <div fxLayout="row" fxLayoutGap="4px" fxLayoutAlign=" center">
        <div class="fw-bold text-primary">Widget Type:</div><span class="fw-normal">{{widgetType()}}</span>
    </div>
    <div fxLayout="row" fxLayoutGap="4px" fxLayoutAlign=" center">
        <div class="fw-bold text-primary">Version:</div><span class="fw-normal">{{widgetData['version']}}</span>
    </div>
    
    <div fxLayout="column" fxLayoutGap="4px" *ngIf="!isCustomCodeWidget()">
        <div class="fw-bold text-primary">Widget Type Description:</div>
        <div class="fw-normal">
            {{ widgetData['description'] }}
        </div>
    </div>

    <div fxLayout="column" fxLayoutGap="4px" *ngIf="isCustomCodeWidget()">
        <div class="fw-bold text-primary">Widget Name:</div>
        <div class="fw-normal">
            {{ widgetConfig['name'] }}
        </div>
    </div>

    <div fxLayout="column" fxLayoutGap="4px" *ngIf="isCustomCodeWidget()">
        <div class="fw-bold text-primary">Widget Description:</div>
        <div class="fw-normal">
            {{ widgetConfig['description'] }}
        </div>
    </div>

    <div fxLayout="row" fxLayoutGap="4px" fxLayoutAlign=" center">
        <div class="fw-bold text-primary">
            More Info
        </div>
    </div>
    <div *ngIf="widgetData['kernals_information']">
        <div class="fw-bold pb-2">Covariance function (kernel) descriptions:</div>
        <div fxLayout="column" fxLayoutGap="16px">
            <div fxLayout="row" fxLayoutGap="16px">
                <div fxFLex="30px" class="fw-bold mt-4">1.</div>
                <div fxFlex>
                    <p><strong>Spectral mixture kernel</strong>. The covariance of the spectral mixture kernel is given
                        by:</p>
                    <div><img src="assets/cf-img-spectral.png" alt="Spectral mixture kernel" class="img-fluid"></div>
                    <p>where q indexes the number of Gaussians in the mixture, p indexes the input features, w_q are the
                        weights for each mixture component, tau_p is the pth component of the vector x - x', mu_q is the
                        qth component of the mean vector, and v_q is the qth component of covariance matrix diagonal. It
                        is not recommended to use the spectral mixture kernel in conjuction with other covariance
                        kernels, and doing so is disabled for the GPR widget.</p>
                </div>
            </div>
            <div fxLayout="row" fxLayoutGap="16px">
                <div fxFLex="30px" class="fw-bold mt-4">2.</div>
                <div fxFlex>
                    <p><strong>RBF kernel</strong>. The covariance of the RBF kernel is given by:</p>
                    <div><img src="assets/cf-img-rbf.png" alt="Spectral mixture kernel" class="img-fluid"></div>
                    <p>where theta is a length scale parameter. The RBF kernel is suitable for modeling smooth,
                        continuous, signals, since it is infinitely differentiable. It is a popular choice for modeling
                        the responses of computer experiments for this reason.</p>
                </div>
            </div>
            <div fxLayout="row" fxLayoutGap="16px">
                <div fxFLex="30px" class="fw-bold mt-4">3.</div>
                <div fxFlex>
                    <p><strong>Matern kernel</strong>. The covariance of the Matern kernel is given by:</p>
                    <p>Computes a covariance matrix based on the Matern kernel between inputs x<sub>1</sub> and
                        x<sub>2</sub>:</p>
                    <div><img src="assets/cf-img-matern1.png" alt="Spectral mixture kernel" class="img-fluid"></div>
                    <p>where</p>
                    <div><img src="assets/cf-img-matern2.png" alt="Spectral mixture kernel" class="img-fluid"></div>
                    <p>is the distance between x<sub>1</sub> and x<sub>2</sub> scaled by the lengthscale parameter ∅.
                    </p>
                    <p><i>v</i> is a smoothness parameter (takes values 1/2, 3/2, or 5/2). Smaller values are less
                        smooth.</p>
                    <p>K<sub>v</sub> is a modified Bessel function.</p>
                </div>
            </div>
            <div fxLayout="row" fxLayoutGap="16px">
                <div fxFLex="30px" class="fw-bold mt-4">4.</div>
                <div fxFlex>
                    <p><strong>Linear kernel</strong>. The covariance of the linear kernel is given by:</p>
                    <p>Computes a covariance matrix based on the Linear kernel between inputs X<sub>1</sub> and
                        X<sub>2</sub>:</p>
                    <div><img src="assets/cf-img-linear.png" alt="Spectral mixture kernel" class="img-fluid"></div>
                    <p>where</p>
                    <p><i>v</i> is a variance parameter.</p>
                </div>
            </div>
        </div>
        <p>Linear kernels are used to capture linear trends within output responses.</p>
        <p>For the ARD kernels (ARD RBF and ARD Matern), a length scale parameter is assigned to each input feature.
            Using an ARD kernel allows for the model to capture the response sensitivity to each input feature, often
            improving model performance. However, ARD kernels require more hyperparameters to be optimized, which may
            make obtaining a good model fit more difficult.</p>
        <div fxLayout="row" fxLayoutGap="4px" fxLayoutAlign=" center">
            <div class="fw-normal text-primary">
                Referral Documentation
            </div>
        </div>
    </div>