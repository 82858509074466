import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { DataCatalogService } from '../../tools/data-catalog/data-catalog.service';
import { ToastrService } from 'ngx-toastr';

interface filtersModel{
  id:string,
  function:string,
  value:string,
  operator:string
}
@Component({
  selector: 'app-advance-search-sigma',
  templateUrl: './advance-search-sigma.component.html',
  styleUrls: ['./advance-search-sigma.component.less'],
})
export class AdvanceSearchSigmaComponent {
  constructor(
    private dataCatService: DataCatalogService,
    public dialogRef: MatDialogRef<AdvanceSearchSigmaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public toaster: ToastrService,
  ) {

    console.log(data,"this data uc2");
  }

  searchItems: string = '';
  itemsList: any[] = [];
  filteredItems: { name: string; selected: boolean }[] = [];
  selectAllItems: boolean = false;
  filters: filtersModel[] = [];
  functionList: { name: string; value: string }[] = [
    { name: 'contains', value: 'LQ' },
    { name: 'inverse', value: 'NLQ' },
    { name: 'Equal', value: 'EQ' },        // Equal corresponds to 'EQ'
    { name: 'Not Equal', value: 'NE' },
    { name: 'Between', value: 'BW' }
  ];
  
  operatorList: string[] = ['AND', 'OR', 'XOR'];
  dropdownType: string = '';

  filteredTopLevelKeys: string[] = [];
  responsePathRun : any;
  runStepGQLFileList: any = [];
  uc2SigmaSelectedData: any = {
    measurement_steps: {
      file_path: '',
      selected_values: [],
    },
    run_parameters: {
      file_path: '',
      selected_values: [],
    },
    point_steps: {
      file_path: '',
      selected_values: [],
    },
    measurement_parameters: {
      file_path: '',
      selected_values: [],
    },
    point_parameters: {
      file_path: '',
      selected_values: [],
    },
    wafer_parameters: {
      file_path: '',
      selected_values: [],
    },
    gql_file_details: {
      parquet_file_path: '',
      gql_file_path: '',
    },
  };

  runParametersSearch: any;
  isUC2SigmaRunParametersSelectAll: boolean = false;
  UC2SigmaSelectedRunParameters: any = [];
  selectedPage: number = 1;

  totalItems: number = 100000; 
  itemsPerPage: number = 1000; 
  totalPages: number | undefined;

  showLoader:boolean = false;

  searchSelectedItems: string = ''; // Search input model
  selectedItemsPage: number = 1; // Current page for selected items
  totalSelectedItems: number = 100000;
  totalSelectedItemsPages: number = 5; // Example total pages (calculate based on data)
  selectedItemsPerPage: number = 10; // Number of items per page

  // Filtered and paginated selected items list
  paginatedSelectedItems: any[] = [];

  selectedItemsArray: string[] = [];

  ngOnInit(): void { 
    this.addNewFilter();
    this.onUC2GenerateUniquesPagination(1);
    this.onSelectedItemsPagination(this.selectedItemsPage);
  }

  onSelectionChange(selectedItem: MatCheckboxChange, item: any): void { 
      this.filteredItems.forEach((item: any) => {
        if (item !== selectedItem) {
          item = false;
        }
      });
      item = selectedItem.checked;
  }

  generateRandomCode(): string {
    const min = 0; 
    const max = 9999; 
    const code = Math.floor(Math.random() * (max - min + 1)) + min;
    return code.toString();
  }

  async applyFiltersFunction() {
    this.showLoader = true;
    this.filteredItems = this.filteredItems.map((item: any) => item);    
    this.filteredItems = this.applyFilters(this.filteredItems, this.filters);
    console.log(this.filters,"filter valurs");

    const pageDetails = {
      pageIndex: 0, 
      pageSize: 10, 
    };

    try {
      const res = await this.dataCatService.getQueryUniques(this.data.path, 'run_parameters', pageDetails, this.filters).toPromise();
      if (res.body['uniques']) { 
        this.showLoader = false;
        this.filteredItems = res.body['uniques'].map((item: string) => ({
          name: item,     
          selected: false 
        }));

        this.totalItems = res.body['rows_count'];
        this.totalPages = Math.max(Math.ceil(this.totalItems / this.itemsPerPage), 1);
        this.uc2SigmaSelectedData.run_parameters.file_path = this.data.path;
        this.runParametersSearch = this.filteredItems;
  
       
        this.isUC2SigmaRunParametersSelectAll =
        this.filteredItems.length === this.UC2SigmaSelectedRunParameters.length;
      }
    
    } catch (error) {
    this.toaster.error('Error in fetching data');
    console.error('Error in pagination fetch:', error);
    }
  }

  applyFilters(array: any[], filters: any[]): any[] { 
    if (!Array.isArray(array) || !Array.isArray(filters)) { 
      return [];
    }

    return array.filter((item) => { 
      let condition = '';
      filters.forEach((filter, index) => { 
        if (index > 0) {
          condition += ' ';
        }

        if (filter.function === 'contains') {
          condition += `item.name.includes('${filter.value}')`;
        }

        if (filter.function === 'inverse') {
          condition += `!item.name.includes('${filter.value}')`;
        }

        if (filter.operator && index < filters.length - 1) {
          condition += `${filter.operator === 'AND' ? ' &&' : ' ||'} `;
        }
      });

      console.log(condition,"filter condition");

      // Evaluate the condition string safely
      try { 
        const result = eval(condition);
        return result;
      } catch (error) {
        return false;
      }
    });
  }

  addNewFilter() {
    this.filters.push({
      id: this.generateRandomCode(),
      function: '',
      value: '',
      operator: '',
    });
  }
  removeFilter(id: any) {
    let index = this.filters.findIndex((filter: any) => filter.id == id);
    if (index != -1) {
      this.filters.splice(index, 1);
    }
  }
  toggleSelectAll() {
    this.selectAllItems = !this.selectAllItems;
    if (this.selectAllItems) {
      this.filteredItems = this.filteredItems.map((item: any) => {
        return {
          ...item, // Preserve other properties of the item
          selected: true, // Set the selected property to true
        };
      });
    } else {
      this.filteredItems = this.filteredItems.map((item: any) => {
        return {
          ...item, // Preserve other properties of the item
          selected: false, // Set the selected property to false
        };
      });
    }
  }
  filteredData() {
    return this.filteredItems.filter((item: any) =>
      item.toLowerCase().includes(this.searchItems.toLowerCase()),
    );
  }

  filterItems() {
    if (!this.searchItems || this.searchItems.trim() === '') {
      this.onUC2GenerateUniquesPagination(1);
    } else {
      const searchQuery = this.searchItems.toLowerCase();
      this.filteredItems = this.filteredItems.filter(item =>
        item.name.toLowerCase().includes(searchQuery) 
      );
    }
  }
  

  enableApplyButton() {
    // Check if any items in filteredItems are selected or deselected
    const hasFilteredSelection = this.filteredItems.some((item: any) => item.selected === true || item.selected === false);
  
    // Check if any items in data.selectedList are selected or deselected (including checked state)
    const hasSelectedListChanges = this.data.selectedList.some((item: any) => item.selected === true || item.selected === false || item.checked === true || item.checked === false);
  
    // Enable the button if any selection or deselection occurred in either list
    return !(hasFilteredSelection || hasSelectedListChanges);
  }
  
  

  onApplyFilter() { 
    console.log(this.filteredItems, "test values", this.selectedItemsArray);

    let selectedFromFilteredItems = this.filteredItems
        .filter(item => item.selected === true)
        .map(item => item);

    // If selectedItemsArray is empty, populate it with the names from paginatedSelectedItems
    if (this.selectedItemsArray.length === 0) {
        this.selectedItemsArray = this.paginatedSelectedItems
            .filter(item => item.checked === true) // Only include checked items
            .map(item => item.name); // Extract the 'name' property only

        let selectedFromDataList = this.selectedItemsArray.map(itemName => {
            return { name: itemName, selected: true }; 
        });

        let mergedSelectedItems = [
            ...selectedFromFilteredItems, 
            ...selectedFromDataList
        ].filter((item, index, self) => 
            self.findIndex(i => i.name === item.name) === index 
        );

        console.log(mergedSelectedItems, "merged selected items");
        this.dialogRef.close({ dataSelected: mergedSelectedItems, dropdown: this.data.dropdown });

    } else {
        // Map selectedItemsArray correctly
        let selectedFromDataList = this.selectedItemsArray.map(itemName => {
            return { name: itemName, selected: true }; 
        });

        let mergedSelectedItems = [
            ...selectedFromFilteredItems, 
            ...selectedFromDataList
        ].filter((item, index, self) => 
            self.findIndex(i => i.name === item.name) === index 
        );

        console.log(mergedSelectedItems, "merged selected items");
        this.dialogRef.close({ dataSelected: mergedSelectedItems, dropdown: this.data.dropdown });
    }
} 


  
  

  onClose() {
    this.dialogRef.close();
  }

  resetAdvanceSearch() {
    this.filteredItems = [...this.filteredItems];
    this.selectedPage = 1;  // Reset page to 1 in the dropdown
    this.onUC2GenerateUniquesPagination(1);  // Trigger pagination for page 1
    this.filters = [];
    this.addNewFilter();  // Reset any filters as needed
  }
  

  async onUC2GenerateUniquesPagination(pageIndex: any) { 
    this.showLoader = true;
    if (!pageIndex || pageIndex < 1) {
      console.error('Invalid page number:', pageIndex);
      return;
    }
  
    const pageDetails = {
      pageIndex: pageIndex - 1, 
      pageSize: 10, 
    };
  
      try {
        const res = await this.dataCatService.getQueryUniques(this.data.path, 'run_parameters', pageDetails).toPromise();  // Ensure it's only called once

      if (res.body['uniques']) { 
      this.showLoader = false;
        this.filteredItems = res.body['uniques'].map((item: string) => ({
          name: item,     
          selected: false 
        }));

        this.totalItems = res.body['rows_count'];
        this.totalPages = Math.max(Math.ceil(this.totalItems / this.itemsPerPage), 1);

        this.uc2SigmaSelectedData.run_parameters.file_path = this.data.path;
        this.runParametersSearch = this.filteredItems;
  
       
        this.isUC2SigmaRunParametersSelectAll =
          this.filteredItems.length === this.UC2SigmaSelectedRunParameters.length;
      }
    } catch (error) {
      this.toaster.error('Error in fetching data');
      console.error('Error in pagination fetch:', error);
    }
  }

  filterSelectedItems() {
    const filtered = this.data.selectedList.filter((item: { name: string; }) => 
      item.name.toLowerCase().includes(this.searchSelectedItems.toLowerCase())
    );
    this.paginateSelectedItems(filtered);
  }
  
  // Function to handle pagination
  onSelectedItemsPagination(page: number) {
    this.selectedItemsPage = page;
    this.paginateSelectedItems();
  }
  
  // Paginate the selected items
  paginateSelectedItems(filteredItems = this.data.selectedList) {
    const startIndex = (this.selectedItemsPage - 1) * this.selectedItemsPerPage;
    const endIndex = startIndex + this.selectedItemsPerPage;
    this.paginatedSelectedItems = filteredItems.slice(startIndex, endIndex);
    this.transformItemsToCheckable(this.paginatedSelectedItems);
    this.totalSelectedItems = filteredItems.length;
    this.totalSelectedItemsPages = Math.max(Math.ceil(this.totalSelectedItems / this.itemsPerPage), 1);
  }
  
  transformItemsToCheckable(items: string[]) {
    this.paginatedSelectedItems = items.map(item => ({
      name: item,
      checked: true // Or false based on your logic
    }));
  }

  onSelectedItemChange(selectedItem: any) {
    const index = this.paginatedSelectedItems.findIndex(item => item.name === selectedItem.name);
    if (index !== -1) {
        this.paginatedSelectedItems[index].checked = selectedItem.checked; 
    }
    this.selectedItemsArray = this.paginatedSelectedItems
        .filter(item => item.checked) 
        .map(item => item.name); 

    console.log(this.selectedItemsArray, "selected array list with checked values");

    this.enableApplyButton();
}



}
