<div fxLayout="row" fxLayoutAlign="space-between center" class="p-6 border-bottom">
  <div>
    <h1>Copy Password</h1>
  </div>
  <div>
    <button mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<div class="p-6">
  <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign=" center" class="fw-bold mb-1">
    <div>Password</div>
    <mat-icon class="material-symbols-outlined text-grey" matTooltip="Password Information">info</mat-icon>
  </div>
  <mat-form-field appearance="outline">
    <input matInput disabled id="password" name="password" [(ngModel)]="password_copy" #passwordInput="ngModel" />
    <button mat-icon-button matSuffix (click)="copyText()">
      <mat-icon>content_copy</mat-icon>
    </button>
  </mat-form-field>
</div>
<div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px" class="p-6">
  <button mat-stroked-button color="primary" mat-dialog-close>Close</button>
</div>