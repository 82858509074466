import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiService } from '../../services/api.service';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { NestedTreeControl } from '@angular/cdk/tree';
import { SelectionModel } from '@angular/cdk/collections';
import { FileNodeResponse } from 'src/app/models/api-models';

@Component({
  selector: 'app-mounted-drive-data-preview',
  templateUrl: './mounted-drive-data-preview.component.html',
  styleUrls: ['./mounted-drive-data-preview.component.less'],
})
export class MountedDriveDataPreviewComponent {
  treeControl = new NestedTreeControl<FileNodeResponse>(
    (node) => node.children,
  );
  dataSource = new MatTreeNestedDataSource<FileNodeResponse>();
  selection = new SelectionModel<FileNodeResponse>(false);
  files: any = [];
  path: any;
  hasChild = (_: number, node: FileNodeResponse) =>
    !!node.children && node.children.length > 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MountedDriveDataPreviewComponent>,
    private apiService: ApiService,
  ) {}

  ngOnInit() {
    this.apiService
      .getMountedDriveDirectoryList(this.data.type)
      .then((response) => {
        if (response) {
          this.files = [response];
          this.dataSource.data = this.files;
          this.treeControl.dataNodes = this.dataSource.data;
          this.treeControl.expandAll();
        }
      })
      .catch((error) => {
        console.error('Error fetching mounted drive data:', error);
      });
  }

  toggleFileSelection(node: any) {
    this.selection.toggle(node);
    if (node.type === 'file') {
      this.path = node.full_path;
    } else if (node.type === 'folder') {
      this.path = node.full_path;
    }
  }

  isFileSelected(node: FileNodeResponse) {
    return this.selection.isSelected(node);
  }

  getSelectedNode(): FileNodeResponse | null {
    return this.selection.selected.length ? this.selection.selected[0] : null;
  }

  onImport() {
    if (this.data.type === 'save') {
      this.dialogRef.close({ success: true, path: this.path });
    } else if (this.data.type === 'csv') {
      const selectedNode = this.getSelectedNode();
      if (selectedNode) {
        this.apiService
          .importMountedDriveFile(
            selectedNode.full_path!,
            selectedNode.name!,
            this.data.description,
            'EXTERNAL',
            '',
          )
          .then((response) => {
            if (response) {
              this.dialogRef.close({
                success: true,
                dataset_id: response.dataset_id,
                path: this.path,
              });
            }
          })
          .catch((error) => {
            console.error('Error fetching mounted drive data:', error);
          });
      } else {
        console.log('No node selected');
      }
    } else if (this.data.type === 'zip') {
      const selectedNode = this.getSelectedNode();
      if (selectedNode) {
        this.dialogRef.close({
          success: true,
          name: selectedNode.name,
          pathname: selectedNode.full_path,
        });
      } else {
        console.log('No node selected');
      }
    } else {
      const selectedNode = this.getSelectedNode();
      if (selectedNode) {
        this.apiService
          .importMountedDriveFile(
            selectedNode.full_path!,
            this.data.name,
            this.data.description,
            'INTERNAL',
            '',
          )
          .then((response) => {
            if (response) {
              this.dialogRef.close({
                success: true,
                dataset_id: response.dataset_id,
                path: this.path,
              });
            }
          })
          .catch((error) => {
            console.error('Error fetching mounted drive data:', error);
          });
      } else {
        console.log('No node selected');
      }
    }
  }

  filterNodes(event: Event) {
    const filterText = (event.target as HTMLInputElement)?.value;
    if (!filterText) {
      this.dataSource.data = this.files;
      this.treeControl.expandAll();
    } else {
      this.dataSource.data = this.filterNode(this.files, filterText);
      this.treeControl.dataNodes = this.dataSource.data;
      this.treeControl.expandAll();
    }
  }

  filterNode(
    nodes: FileNodeResponse[],
    filterText: string,
  ): FileNodeResponse[] {
    const filteredNodes: FileNodeResponse[] = [];

    nodes.forEach((node) => {
      const children = node.children
        ? this.filterNode(node.children, filterText)
        : [];

      if (
        node.name!.toLowerCase().includes(filterText.toLowerCase()) ||
        children.length > 0
      ) {
        const newNode = { ...node, children };
        filteredNodes.push(newNode);
      }
    });

    return filteredNodes;
  }
}
