<div class="h-100 p-8" fxLayout="column" fxLayoutGap="16px">
  <div fxFlex="none">
    <h1 class="global-main-title">Page Heading</h1>
  </div>
  <div fxFlex="none" fxLayout="row" fxLayoutAlign="space-between center">
    <div fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="16px">
      <div>
        <mat-form-field appearance="outline">
          <mat-label>Search</mat-label>
          <mat-icon matPrefix>search</mat-icon>
          <input matInput placeholder="Search" />
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="outline">
          <mat-label>Type</mat-label>
          <mat-select>
            <mat-option value="Option1">Option 1</mat-option>
            <mat-option value="Option2">Option 2</mat-option>
            <mat-option value="Option3">Option 3</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div>
        <mat-form-field appearance="outline">
          <mat-label>Uploader</mat-label>
          <mat-select>
            <mat-option value="Option1">Option 1</mat-option>
            <mat-option value="Option2">Option 2</mat-option>
            <mat-option value="Option3">Option 3</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div></div>
    </div>
    <div fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="16px">
      <div>
        <mat-button-toggle-group>
          <mat-button-toggle id="tile-button">
            <mat-icon class="material-symbols-outlined">grid_view</mat-icon>
          </mat-button-toggle>
          <mat-button-toggle id="list-button">
            <mat-icon class="material-symbols-outlined">table_rows</mat-icon>
          </mat-button-toggle>
        </mat-button-toggle-group>
      </div>
      <div>
        <button
          mat-flat-button
          color="primary"
          matBadge="15"
          matBadgeSize="small"
          matBadgeColor="warn"
          matBadgeOverlap="true"
        >
          <mat-icon class="material-symbols-outlined">upload</mat-icon>
          Import data
        </button>
      </div>
    </div>
  </div>
  <div fxFlex>
    <section class="pb-5">
      <h2 class="pb-2">Table</h2>
      <table mat-table [dataSource]="dataSource">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let element">{{ element.name }}</td>
        </ng-container>
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef>Type</th>
          <td mat-cell *matCellDef="let element">
            <div class="global-type-chip">{{ element.type }}</div>
          </td>
        </ng-container>
        <ng-container matColumnDef="size">
          <th mat-header-cell *matHeaderCellDef>Size</th>
          <td mat-cell *matCellDef="let element">{{ element.size }}</td>
        </ng-container>
        <ng-container matColumnDef="uploader">
          <th mat-header-cell *matHeaderCellDef>Uploader</th>
          <td mat-cell *matCellDef="let element">{{ element.uploader }}</td>
        </ng-container>
        <ng-container matColumnDef="last_modified">
          <th mat-header-cell *matHeaderCellDef>Last Modified <span class="text-sm text-grey">(Local Time)</span></th>
          <td mat-cell *matCellDef="let element">
            {{ element.last_modified }}
          </td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef width="40">&nbsp;</th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button [matMenuTriggerFor]="actionsMenu">
              <mat-icon>more_vert</mat-icon>
            </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </section>
    <mat-divider></mat-divider>
    <section class="py-5" fxLayout="row" fxLayoutGap="24px">
      <div fxFlex>
        <h2 class="pb-2">Checkbox</h2>
        <mat-checkbox color="primary">Option 1</mat-checkbox>
        <mat-checkbox color="primary">Option 2</mat-checkbox>
      </div>
      <div fxFlex>
        <h2 class="pb-2">Radio button</h2>
        <mat-radio-group color="primary">
          <mat-radio-button value="1">Option 1</mat-radio-button>
          <mat-radio-button value="2">Option 2</mat-radio-button>
        </mat-radio-group>
      </div>
      <div fxFlex>
        <h2 class="pb-2">Datepicker</h2>
        <mat-form-field appearance="outline">
          <mat-label>Choose a date</mat-label>
          <input matInput [matDatepicker]="picker" />
          <mat-datepicker-toggle
            matIconSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>
    </section>
    <mat-divider></mat-divider>
    <section class="py-5" fxLayout="row" fxLayoutGap="24px">
      <div fxFlex>
        <h2 class="pb-2">Chips</h2>
        <mat-chip-listbox color="primary">
          <mat-chip-option>Option 1</mat-chip-option>
          <mat-chip-option>Option 2</mat-chip-option>
          <mat-chip-option selected>Option 3</mat-chip-option>
          <mat-chip-option>Option 4</mat-chip-option>
        </mat-chip-listbox>
      </div>
      <div fxFlex>
        <h2 class="pb-2">Icons</h2>
        <mat-icon class="material-symbols-outlined">more_vert</mat-icon>
        <mat-icon class="material-symbols-outlined text-orange"
          >notifications</mat-icon
        >
        <mat-icon class="material-symbols-outlined text-green"
          >check_circle</mat-icon
        >
        <mat-icon class="material-symbols-outlined text-red">error</mat-icon>
        <mat-icon class="material-symbols-outlined text-grey"
          >settings</mat-icon
        >
        <mat-icon class="material-symbols-outlined text-blue">delete</mat-icon>
      </div>
      <div fxFlex>
        <h2 class="pb-2">Slide Toggle</h2>
        <mat-slide-toggle color="primary">Slide</mat-slide-toggle>
      </div>
      <div fxFlex="none">
        <h2 class="pb-2">Slider</h2>
        <mat-slider>
          <input matSliderThumb />
        </mat-slider>
      </div>
      <div fxFlex="none">
        <h2 class="pb-2">Spinner</h2>
        <mat-spinner diameter="24"></mat-spinner>
      </div>
    </section>
    <section class="py-5" fxLayout="row" fxLayoutGap="24px">
      <div fxFlex>
        <h2 class="pb-2">Tabs</h2>
        <mat-tab-group mat-stretch-tabs="false">
          <mat-tab label="First"> <div class="p-4">Content 1</div> </mat-tab>
          <mat-tab label="Second"> <div class="p-4">Content 2</div> </mat-tab>
          <mat-tab label="Third"> <div class="p-4">Content 3</div> </mat-tab>
        </mat-tab-group>
      </div>
    </section>
  </div>
</div>

<mat-menu #actionsMenu="matMenu">
  <button mat-menu-item>Edit</button>
  <button mat-menu-item>Delete</button>
</mat-menu>
