<div fxLayout="column" fxLayoutAlign="center center" class="h-100" *ngIf="!sessionsList">
  <mat-spinner diameter="48"></mat-spinner>
</div>
<div fxLayout="column" class="session-container" *ngIf="sessionsList">
  <div fxFlex="none" *ngIf="showMainHeader"><app-header></app-header></div>
  <div fxFlex="none" fxLayout="row" fxLayoutAlign="space-between center" class="header">
    <div fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="4px">
      <div>
        <button mat-icon-button class="global-back-button" (click)="navigate('tools')">
          <mat-icon>arrow_back</mat-icon>
        </button>
      </div>
      <div class="title">Data Catalog</div>
    </div>
    <div fxLayout="row" fxLayoutAlign=" center" fxLayoutGap="4px">
      <div class="border-left">
        <button mat-icon-button class="global-icon-button" (click)="toggleMainHeader()">
          <mat-icon class="material-symbols-outlined">
            {{ toggleHeaderButton }}
          </mat-icon>
        </button>
      </div>
    </div>
  </div>
  <div fxFlex fxLayout="column" fxLayoutGap="16px" class="p-6">
    <div fxFlex="none" fxLayout="row" fxLayoutAlign="space-between center">
      <div>
        <h1 class="global-main-title">Sessions</h1>
      </div>
      <div>
        <div *ngIf="
            role_id == 1 || role_id_sso == 1 || role_id == 2 || role_id_sso == 2
          ">
          <button mat-flat-button color="primary" (click)="createNewSession()"
            (closePanelClicked)="onCloseCreateNewSession()">
            <mat-icon class="material-symbols-outlined">add</mat-icon>
            Create <span fxHide.lt-md>Session</span>
          </button>
        </div>
      </div>
    </div>
    <div fxFlex fxLayoutAlign="center center" *ngIf="sessionsList && sessionsList.length === 0">
      <div class="global-nodata">
        No Sessions Exist.
      </div>
    </div>
    <div fxFlex class="y-auto" *ngIf="sessionsList && sessionsList.length > 0">
      <table mat-table [dataSource]="sessionsList">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Name</th>
          <td mat-cell *matCellDef="let element" (click)="onSessionClick(element)">
            {{ element.name }}
          </td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef>Description</th>
          <td mat-cell *matCellDef="let element" (click)="onSessionClick(element)">
            {{ element.description }}
          </td>
        </ng-container>

        <ng-container matColumnDef="owner_name">
          <th mat-header-cell *matHeaderCellDef>Owner</th>
          <td mat-cell *matCellDef="let element" (click)="onSessionClick(element)">
            {{ element.owner_name }}
          </td>
        </ng-container>

        <ng-container matColumnDef="created_at">
          <th mat-header-cell *matHeaderCellDef>Created At</th>
          <td mat-cell *matCellDef="let element" (click)="onSessionClick(element)">
            {{ element.created_at | date: "dd-MMM-yyyy h:mm a" }}
          </td>
        </ng-container>

        <ng-container matColumnDef="more_vert">
          <th mat-header-cell *matHeaderCellDef width="40">&nbsp;</th>
          <td mat-cell *matCellDef="let element">
            <button mat-icon-button (click)="deleteConfirmation = false" [matMenuTriggerFor]="actionsMenu"
              [matMenuTriggerData]="{ rowData: element }">
              <mat-icon>more_vert</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns" [ngStyle]="{ cursor: 'pointer'}"></tr>
      </table>
    </div>
  </div>
</div>

<mat-menu #actionsMenu="matMenu" class="global-menu">
  <ng-template matMenuContent let-rowData="rowData">
    <ng-container *ngIf="!deleteConfirmation">
      <button mat-menu-item (click)="onSessionClick(rowData)">
        <mat-icon class="material-symbols-outlined">visibility</mat-icon> View
      </button>
      <!-- <button mat-menu-item (click)="updateProject(rowData)"
        *ngIf="isProjectAdministrator() || role_id == 1 || role_id_sso == 1">
        <mat-icon class="material-symbols-outlined">edit</mat-icon> Edit
      </button> -->
      <button mat-menu-item (click)="deleteConfirmation = true; $event.stopPropagation(); openConfirmationDialog()"
        >
        <mat-icon class="material-symbols-outlined">delete</mat-icon> Delete
      </button>
    </ng-container> 

    <ng-template #confirmationDialogTemplate>
      <!-- <h1 mat-dialog-title>Project Delete Confirmation</h1> -->
      <div mat-dialog-content>
        <p>Are You Sure? This (<strong>{{rowData.name}}</strong>) will <strong>not delete</strong> files in TDAM-SPOCK-Share</p>
      </div>
      <div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px">
        <button mat-flat-button (click)="onCancel()">Cancel</button>
        <button mat-flat-button color="primary" (click)="deleteSession(rowData)">Confirm</button>
      </div>
    </ng-template>
  </ng-template>
</mat-menu>