<div fxLayout="row" fxLayoutAlign="space-between center" class="p-6 border-bottom">
  <div>
    <h1>Create New Password</h1>
  </div>
  <div>
    <button mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
<div fxLayout="column" fxLayoutGap="24px" class="p-6">
  <div>
    <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign=" center" class="fw-bold mb-1">
      <div>Email</div>
      <mat-icon class="material-symbols-outlined text-grey" matTooltip="The name of the project.">info</mat-icon>
    </div>
    <mat-form-field appearance="outline">
      <input matInput required id="email" name="email" [(ngModel)]="_data.email" disabled #emailModel="ngModel"
        placeholder="Enter email" />
    </mat-form-field>
    <mat-error *ngIf="emailModel.invalid && emailModel.touched">
      Please enter a valid email.
    </mat-error>
  </div>
  <div>
    <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign=" center" class="fw-bold mb-1">
      <div>Reason</div>
      <mat-icon class="material-symbols-outlined text-grey"
        matTooltip="The Reason of the password change.">info</mat-icon>
    </div>
    <mat-form-field appearance="outline">
      <textarea matInput required id="reason" name="reason" [(ngModel)]="reason" #reasonModel="ngModel"
        placeholder="Enter reason"></textarea>
    </mat-form-field>

    <mat-error *ngIf="reasonModel.invalid && reasonModel.touched">
      Please provide a reason.
    </mat-error>
  </div>
</div>
<div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="16px" class="p-6">
  <button mat-stroked-button color="primary" id="closeButton" mat-dialog-close>
    Cancel
  </button>
  <button mat-flat-button color="primary" id="submitButton" (click)="onCreatePassword()">
    Create New Password
  </button>
</div>