import { Component, OnInit } from '@angular/core';
import { ConfigService } from 'src/app/services/config.service';
import { Router } from '@angular/router';
import { DataSheetGenerateService } from '../data-sheet-generate.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-dsg-files',
  templateUrl: './dsg-files.component.html',
  styleUrls: ['./dsg-files.component.less'],
})
export class DsgFilesComponent implements OnInit {
  showMainHeader: boolean = false;
  toggleHeaderButton: string = 'expand_more';
  initialFolders: string[] = [];
  childrenData: { [key: string]: any[] } = {};
  expandedNodes: { [key: string]: boolean } = {};
  projectId!: string;

  constructor(
    private configService: ConfigService,
    private datasheetService: DataSheetGenerateService,
    private router: Router,
    public toaster: ToastrService,
  ) {
    const selectedProjectId: string | undefined =
      this.configService.SelectedProjectId;
    if (selectedProjectId) {
      this.projectId = selectedProjectId;
    }
  }

  ngOnInit() {
    this.loadInitialFolders();
  }

  navigate(pageName: string) {
    let selectedProjectId: string | undefined =
      this.configService.SelectedProjectId;
    if (!selectedProjectId) {
      return;
    }
    const projectId = selectedProjectId;
    const siteId = this.configService.SelectedSiteId;
    this.router.navigate([`sites/${siteId}/projects/${projectId}/${pageName}`]);
  }

  toggleMainHeader(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.showMainHeader = !this.showMainHeader;
    this.toggleHeaderButton = this.showMainHeader
      ? 'expand_less'
      : 'expand_more';
  }

  loadInitialFolders() {
    const requestData = { base_path: 'base' };
    this.datasheetService
      .getDatasheetDetails(requestData, this.projectId)
      .subscribe(
        (data) => {
          this.initialFolders = data.datasheets;
          this.initialFolders.forEach((folder) => {
            this.expandedNodes[folder] = false;
          });
        },
        (error) => {
          console.error('Error loading initial folders:', error);
        },
      );
  }

  loadChildren(path: string) {
    if (this.isFilePath(path)) {
      return;
    }
    const requestData = { base_path: path };
    this.datasheetService
      .getDatasheetDetails(requestData, this.projectId)
      .subscribe(
        (data) => {
          this.childrenData[path] = data.datasheets;
          this.expandedNodes[path] = true;
        },
        (error) => {
          console.error('Error fetching children:', error);
        },
      );
  }

  onToggle(path: string) {
    if (this.expandedNodes[path]) {
      this.expandedNodes[path] = false;
    } else {
      if (!this.childrenData[path]) {
        this.loadChildren(path);
      } else {
        this.expandedNodes[path] = true;
      }
    }
  }

  onDelete(path: string) {
    this.datasheetService.deleteDatasheet(this.projectId, path).subscribe({
      next: (results) => {
        if (results.success) {
          this.initialFolders = this.initialFolders.filter(
            (folder) => folder !== path,
          );
          delete this.childrenData[path];
          delete this.expandedNodes[path];
          Object.keys(this.childrenData).forEach((parentPath) => {
            this.childrenData[parentPath] = this.childrenData[
              parentPath
            ].filter((child) => child !== path);
          });

          this.toaster.success('Datasheet deleted successfully', '', {
            positionClass: 'custom-toast-position',
          });
        }
      },
      error: (error) => {
        console.error('Error deleting folder:', error);
        this.toaster.error('Unable to delete datasheet, please try again', '', {
          positionClass: 'custom-toast-position',
        });
      },
      complete: () => {},
    });
  }

  isFilePath(path: string): boolean {
    const fileExtensionPattern = /\.[^\/]+$/;
    return fileExtensionPattern.test(path);
  }
}
