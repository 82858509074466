<div class="login-container">
    <div class="login-box">
      <div class="logo"><img src="../../../assets/logo.svg" /></div>
      <div class="title">Knowledge Fusion Platform</div>
      <div class="sub-title">Reset Password</div>
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="pb-5">
          <div class="pb-2">Enter New Password</div>
          <mat-form-field appearance="outline">
            <input [type]="passwordVisible ? 'text' : 'password'" matInput placeholder="Enter new password"
              formControlName="password" required />
              <mat-error *ngIf="f['password'].errors && f['password'].touched">
                <span *ngIf="f['password'].errors['required']">Password is required.</span>
                <span *ngIf="f['password'].errors['minlength']">Password must be at least 12 characters long.</span>
                <span *ngIf="f['password'].errors['pattern']">Password must contain at least one uppercase, one lowercase, one digit, and one special character.</span>
              </mat-error>
            <button matSuffix mat-icon-button (click)="togglePasswordVisibility()">
              <mat-icon *ngIf="!passwordVisible" class="material-symbols-outlined">visibility_off</mat-icon>
              <mat-icon *ngIf="passwordVisible" class="material-symbols-outlined">visibility_on</mat-icon>
            </button>
          </mat-form-field>
        </div>
        <div class="pb-2">
          <div class="pb-2">Confirm Password</div>
          <mat-form-field appearance="outline">
            <input [type]="passwordVisible ? 'text' : 'password'" matInput placeholder="Confirm password"
              formControlName="confirmPassword" required />
              <mat-error *ngIf="f['confirmPassword'].errors && f['confirmPassword'].touched">
                <span *ngIf="f['confirmPassword'].errors['required']">Confirm Password is required.</span>
              </mat-error>
              <mat-error *ngIf="form.get('confirmPassword')?.touched && form.errors?.['mismatch']">
                <span>Passwords must match.</span>
              </mat-error>
            <button matSuffix mat-icon-button (click)="togglePasswordVisibility()">
              <mat-icon *ngIf="!passwordVisible" class="material-symbols-outlined">visibility_off</mat-icon>
              <mat-icon *ngIf="passwordVisible" class="material-symbols-outlined">visibility_on</mat-icon>
            </button>
          </mat-form-field>
        </div>        
        <div>
          <button mat-flat-button color="primary" class="w-100"  type="submit" [disabled]="form.invalid">
            Save
          </button>
        </div>   
      </form>
    </div>
  </div>